import { getFirestore, collection, addDoc, doc, writeBatch, getDocs, deleteDoc } from 'firebase/firestore';

export default async function writeExternalTests(labTests) {
  const db = getFirestore();
  const testsCollection = collection(db, 'external_tests');

  // Delete existing documents in the collection (if needed)
  await deleteCollection(testsCollection); 

  const batchSize = 500; 
  for (let i = 0; i < labTests.length; i += batchSize) {
    const batch = writeBatch(db);
    const batchTests = labTests.slice(i, i + batchSize);

    // Use a for...of loop instead of forEach to ensure sequential execution
    for (const test of batchTests) { 
      // Generate a unique ID for the document
      const testDocRef = doc(testsCollection); 
      test.id = testDocRef.id; 
      // Use setDoc with the generated reference to write the data
      batch.set(testDocRef, test); 
    }

    await batch.commit(); 
  }
}

async function deleteCollection(collectionRef) {
  const querySnapshot = await getDocs(collectionRef);
  const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
  await Promise.all(deletePromises); 
}