import React, { useState, useEffect,  forwardRef, useImperativeHandle } from 'react';

import {
  AddButton,
  LineWrapper,
  TariffInput,
  NameInput,
  CategoryInput,
  PriceInput,
  RemoveButton
} from './styles/add-on-test';

const AddOnTest = forwardRef(({ children, onTestsChange, ...restProps }, ref) => {
  const [addOnTests, setAddOnTests] = useState([]);
  
  useEffect(() => {
    // Call onTestsChange whenever addOnTests change
    onTestsChange(addOnTests); 
  }, [addOnTests, onTestsChange]); 

 
  const addTest = () => {
    setAddOnTests([...addOnTests, { tariff_code: '', long_name: '', short_name: '', category: '', lab: '999', reduced_price: '', vat_amount: '', displayed_price: '' }]);
  };

  const removeTest = (index) => {
    setAddOnTests(addOnTests.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedTests = [...addOnTests];
    updatedTests[index][field] = value;

    if (field === 'displayed_price') {
      // Remove any non-digit or non-decimal characters
      //const cleanedValue = value.replace(/[^0-9]/g, ''); 
      const reducedPrice = parseFloat(value);
  
      if (!isNaN(reducedPrice)) {
        const vatRate = 0.15;
        const vatAmount = reducedPrice * vatRate;
        updatedTests[index].vat_amount = vatAmount.toFixed(2);
        updatedTests[index].reduced_price = reducedPrice.toFixed(2); // Store the raw value
        //updatedTests[index].display_price 
      } else {
        // Handle cases where the price is not a valid number
        updatedTests[index].vat_amount = 0; 
        updatedTests[index].reduced_price = 0; 
      }
    }
    
    setAddOnTests(updatedTests);
  };

  useImperativeHandle(ref, () => ({
    setAddOnTests 
  }));


  return (
    <div>
      {addOnTests.map((test, index) => (
        <div key={index}> 
          <LineWrapper> {/* Wrap CategoryInput */}
            <AddOnTest.CategoryInput 
              value={test.category} 
              onChange={(e) => handleInputChange(index, 'category', e.target.value)} 
            />
          </LineWrapper>
          <LineWrapper> {/* Wrap other inputs */}
            <AddOnTest.TariffInput 
              value={test.tariff_code} 
              onChange={(e) => handleInputChange(index, 'tariff_code', e.target.value)} 
            />
            <AddOnTest.NameInput 
              value={test.long_name} 
              onChange={(e) => handleInputChange(index, 'long_name', e.target.value)} 
            />
            <AddOnTest.PriceInput 
              value={test.displayed_price} 
              onChange={(e) => handleInputChange(index, 'displayed_price', e.target.value)} 
            />
            <AddOnTest.RemoveButton onClick={() => removeTest(index)} /> 
          </LineWrapper>
        </div>
      ))}
      <AddOnTest.AddButton onClick={addTest} />
    </div>
  );
}
);


AddOnTest.AddButton = function AddOnTestAddButton({ onClick, ...restProps }) {
  return <AddButton onClick={onClick} {...restProps}>+</AddButton>;
};

AddOnTest.TariffInput = function AddOnTestTariffInput({ value, onChange, ...restProps }) {
  return (
    <TariffInput 
      type="text" 
      placeholder="T.C." 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.NameInput = function AddOnTestNameInput({ value, onChange, ...restProps }) {
  return (
    <NameInput 
      type="text" 
      placeholder="Test Name" 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.CategoryInput = function AddOnTestCategoryInput({ value, onChange, ...restProps }) {
  return (
    <CategoryInput 
      type="text" 
      placeholder="Test Category" 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.PriceInput = function AddOnTestPriceInput({ value, onChange, ...restProps }) {
  return (
    <PriceInput 
      type="text" 
      placeholder="Price(ex VAT)" 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.RemoveButton = function AddOnTestRemoveButton({ onClick, ...restProps }) {
  return <RemoveButton onClick={onClick} {...restProps}>-</RemoveButton>;
};

export default AddOnTest;