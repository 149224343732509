import styled from 'styled-components/macro';

//export const Container = styled.div`
//  display: flex;
//  border-bottom: 8px solid #222;
//`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 660px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 100%;
  margin: auto;
  max-width: 450px;
  padding: 60px 68px 40px;
  margin-bottom: 100px;
`;

export const Frame = styled.div`
  margin-bottom: 40px;
`;

export const Inner = styled.div`
  display: flex;
  padding: 70px 45px;
  flex-direction: column;
  max-width: 550px;
  margin: auto;
`;

export const SubPrice = styled.div`
  display: flex;
  padding: 0px 0px;
  justify: right;
  max-width: 100px;
  margin: auto;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 28px;
 `; 

export const LongContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

export const RightText = styled.div`
margin-left: 15px;
font-size: 15px;
text-align: right;
`;

export const LeftText = styled.div`
margin-right: auto;
font-size: 15px;
text-align: left;
`;

export const TariffText = styled.div`
font-size: 10px;
display: flex;
min-width: 30px;
align-items: center;
//justify-content: center;
font-weight: italic
text-align: left;
`;


export const SubTotal = styled.div`
display: flex;
color: black;
text-decoration: bold;
justify-content: right;
cursor: pointer;
margin-bottom: 1px;
margin-top: 10px;
font-size: 15px;
font-weight: bold;
background: rgba(178, 178, 102, 0.5);
border-radius: 4px;
padding: 0.8em 1.2em 0.8em 1.2em;
user-select: none;
align: right;
  `;

  export const SearchCategory = styled.div`
display: flex;
color: white;
text-decoration: bold;
justify-content: left;
margin-bottom: 1px;
margin-top: 1px;
font-size: 13px;
font-weight: bold;
background: rgba(178, 178, 102, 0.5);
border-radius: 4px;
padding: 3px 1px 2px 10px;
align: left;
max-width: 450px;
width: 100%;
  `;

  export const SearchHeading = styled.div`
display: flex;
color: white;
text-decoration: bold;
justify-content: left;
margin-bottom: 1px;
margin-top: 1px;
font-size: 13px;
font-weight: bold;
background: rgba(24, 156, 173, 0.9);
border-radius: 4px;
padding: 3px 1px 2px 10px;
align: left;
max-width: 450px;
width: 100%;
  `;

  export const LongSearchContainer = styled.div`
  display: flex;
  color: white;
  justify-content: left;
  margin-bottom: 1px;
  margin-top: 1px;
  font-size: 11px;
  background: rgba(30, 30, 30, 0.5);
  border-radius: 4px;
  padding: 1px 1px 1px 10px;
  align: left;
  max-width: 450px;
  width: 100%;
    `;

export const PriceCategory = styled.div`
display: flex;
color: black;
justify-content: left;
cursor: pointer;
margin-bottom: 1px;
margin-top: 10px;
font-size: 15px;
font-weight: bold;
background: rgba(178, 178, 102, 0.5);
border-radius: 4px;
padding: 0.8em 1.2em 0.8em 1.2em;
user-select: none;
align: right;
  `;

export const TotalPrice = styled.div`
display: flex;
color: black;
justify-content: right;
cursor: pointer;
margin-bottom: 1px;
margin-top: 10px;
font-size: 20px;
font-weight: bold;
background: #b2b266;
border-radius: 4px;
padding: 0.8em 1.2em 0.8em 1.2em;
user-select: none;
align: right;
  `;

export const Item = styled.div`
  color: white;
  margin-bottom: 1px;
  margin-top: 1px;
  max-width: 450px;
  width: 100%;
  
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 1px;
  font-size: 20px;
  font-weight: normal;
  background: #b2b266;
  border-radius: 4px;
  padding: 0.4em 1.2em 0.4em 1.2em;
  user-select: none;
  align-items: center;
  img {
    filter: brightness(0) invert(1);
    width: 24px;
    user-select: none;
    @media (max-width: 600px) {
      width: 16px;
    }
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const Body = styled.div`
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  background: #303030;
  white-space: pre-wrap;
  border-radius: 4px;
  user-select: none;
  overflow: hidden;
  &.closed {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s cubic-bezier(0.5, 0, 0.1, 1);
  }
  &.open {
    max-height: 500px;
    transition: max-height 0.25s cubic-bezier(0.5, 0, 0.1, 1);
  }
  span {
    display: block;
    padding: 0.8em 1.2em 0.8em 1.2em;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const InfoButton = styled.div`
  display: block;
  background-color: #b2b266;;
  height: fit-content;
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  color: black;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: #a0a044;;
  }
`;


export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 10px;
  align: left;
  display: flex;
  text-align: left;
  accent-color: #49b9ec;
  width: 40px;
  height: 20px;
  background: #303030
  border-radius: 3px;
  transition: all 150ms;
`;

export const SelectBox = styled.input.attrs({ type: 'radio' })`
  display: inline-block;
  accent-color: #49b9ec;
  width: 40px;
  height: 20px;
  background: #303030
  border-radius: 3px;
  margin-bottom: 20px;
  transition: all 150ms;
`;

export const SelectText = styled.div`
font-size: 15px;
color: white;
margin: auto;
margin-bottom: 20px;
max-width: 450px;
width: 100%;
`;