// import { firebase } from '../lib/firebase.prod';

import { getFirestore, collection, where, query, getDocs } from 'firebase/firestore';

// Assuming you've initialized your Firebase app elsewhere
const db = getFirestore();

export default async function getExternalTests() {
  try {
    const externalTestsRef = collection(db, "external_tests");
    const testsQuery = query(externalTestsRef);
    const snapshot = await getDocs(testsQuery);

    const testsResult = snapshot.docs.map((resultsObj) => ({
      ...resultsObj.data(),
    }));

    return testsResult; // Return directly from async function
  } catch (error) {
    throw new Error(error.message); // Rethrow for better handling upstream
  }
}