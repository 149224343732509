import React, { useState, useContext, createContext } from 'react';
import { Container, Frame, Title, Item, Inner, PriceCategory, CheckBox, SelectBox, Header, Body, SelectText, TotalPrice, SubPrice, RightText, LeftText,
  TariffText, LongContainer, SubTotal, InfoButton, SearchCategory, LongSearchContainer, SearchHeading } from './styles/accordion-tests';

const ToggleContext = createContext();

export default function AccordionTests({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
    //<Container {...restProps}>
    //  <Inner>{children}</Inner>
    //</Container>
  );
}

AccordionTests.TotalPrice = function AccordionTestsTotalPrice({ children, ...restProps }) {
  return <TotalPrice {...restProps}>{children}</TotalPrice>;
};

AccordionTests.SubTotal = function AccordionTestsSubTotal({ children, ...restProps }) {
  return <SubTotal {...restProps}>{children}</SubTotal>;
};

AccordionTests.PriceCategory = function AccordionTestsPriceCategory({ children, ...restProps }) {
  return <PriceCategory {...restProps}>{children}</PriceCategory>;
};

AccordionTests.SubPrice = function AccordionTestsSubPrice({ children, ...restProps }) {
  return <SubPrice {...restProps}>{children}</SubPrice>;
};

AccordionTests.Title = function AccordionTestsTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

AccordionTests.LeftText = function AccordionTestsLeftText({ children, ...restProps }) {
  return <LeftText {...restProps}>{children}</LeftText>;
};

AccordionTests.TariffText = function AccordionTestsTariffText({ children, ...restProps }) {
  return <TariffText {...restProps}>{children}</TariffText>;
};

AccordionTests.RightText = function AccordionTestsRightText({ children, ...restProps }) {
  return <RightText {...restProps}>{children}</RightText>;
};

AccordionTests.LongContainer = function AccordionTestsLongContainer({ children, ...restProps }) {
  return <LongContainer {...restProps}>{children}</LongContainer>;
};

AccordionTests.SearchCategory = function AccordionTestsSearchCategory({ children, ...restProps }) {
  return <SearchCategory {...restProps}>{children}</SearchCategory>;
};

AccordionTests.SearchHeading = function AccordionTestsSearchHeading({ children, ...restProps }) {
  return <SearchHeading {...restProps}>{children}</SearchHeading>;
};

AccordionTests.LongSearchContainer = function AccordionTestsLongSearchContainer({ children, ...restProps }) {
  return <LongSearchContainer {...restProps}>{children}</LongSearchContainer>;
};

AccordionTests.CheckBox = function AccordionTestsCheckBox({ children, ...restProps }) {
  return <CheckBox {...restProps}>{children}</CheckBox>;
};

AccordionTests.SelectBox = function AccordionTestsSelectBox({ children, ...restProps }) {
  return <SelectBox {...restProps}>{children}</SelectBox>;
};

AccordionTests.SelectText = function AccordionTestsSelectText({ children, ...restProps }) {
  return <SelectText {...restProps}>{children}</SelectText>;
};

AccordionTests.InfoButton = function AccordionTestsInfoButton({ children, ...restProps }) {
  return <InfoButton {...restProps}>{children}</InfoButton>;
};

AccordionTests.Frame = function AccordionTestsFrame({ children, ...restProps }) {
  return <Frame {...restProps}>{children}</Frame>;
};

AccordionTests.Item = function AccordionTestsItem({ children, toggle, ...restProps }) {
  const [toggleShow, setToggleShow] = useState(toggle);
  //const { toggleShow, setToggleShow } = useContext(ToggleContext);
  //setToggleShow(toggle); //smsm dev-accordion

  return (
    <ToggleContext.Provider value={{ toggleShow, setToggleShow }}>
      <Item {...restProps}>{children}</Item>
    </ToggleContext.Provider>
  );
};

AccordionTests.Header = function AccordionTestsHeader({ children, ...restProps }) {
  const { toggleShow, setToggleShow } = useContext(ToggleContext);

  return (
    <Header onClick={() => setToggleShow(!toggleShow)} {...restProps}>
      {children}
      {toggleShow ? (
        <img src="/images/icons/close-slim.png" alt="Close" />
      ) : (
        <img src="/images/icons/add.png" alt="Open" />
      )}
    </Header>
  );
};

AccordionTests.Body = function AccordionTestsBody({ children, ...restProps }) {
  const { toggleShow } = useContext(ToggleContext);

  /* return toggleShow ? <Body {...restProps}>{children}</Body> : null; */

  return (
    <Body className={toggleShow ? 'open' : 'closed'} {...restProps}>
      <span>{children}</span>
    </Body>
  );
};


