//todo - stop users from proceeding to order if no tests are selected

import React, { useState, useEffect} from 'react';
import { useRef } from 'react';

// newsearch
import fuse from "fuse.js";
// **newsearch

import { useHistory } from 'react-router-dom'; 
import { AccordionTests, OptForm, Form, AddOnTest, Loading, Accordion} from '../components';
import { writeIntendedOrder } from '../utils';
import { writeIncompleteOrder } from '../utils';
import { writePayLaterOrder } from '../utils';
import { usePatientSearch } from '../hooks';
import { getExternalTests } from "../utils";
import { usePracticeDoctors } from '../hooks';
import { usePracticeInfo } from '../hooks';
import * as ROUTES from '../constants/routes';
// import { UserContext } from '../context/UserContext';
import InputMask from 'react-input-mask';
import { getSiteData } from '../utils';
import { getInvoiceNumber } from '../utils';
import { createOrderSummaryForm } from '../utils';
import { createSiteDirections } from '../utils';
import { getIncompleteOrder } from '../utils';

export function TestsContainer({props}) {
  const addOnTestRef = useRef(null); 
  const history = useHistory();
  const [filteredTests, setFilteredTests] = useState([]);
  const [checkoutTests, setCheckoutTests] = useState([]);
  const [profile, setProfile] = useState(props.profile);
  const [orderId, setOrderId] = useState(props.orderId);
  const [incompleteOrderId, setIncompleteOrderId] = useState("0");
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('tests');
  // const [testGroups, setTestGroups] = useState([]);
  // const [testCount, setTestCount] = useState([]);
  const [externalTests, setExternalTests] = useState({});
  const [retrievedExternalTests, setRetrievedExternalTests] = useState({});
  const [addOnTests, setAddOnTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState({});
  const [retrievedTests, setRetrievedTests] = useState({}); //tests selected previosuly, either saved to DB or from a cookie
  // const [additionalTests, setAdditionalTests] = useState({});
  // const [groupedTests, setGroupedTests] = useState([]); //for rendering purposes organised by category
  
  const [selectedSite, setSelectedSite] = useState({});
  const [orderInfo, setOrderInfo] = useState({});  //where we store name, surname, id, doctors name etc. associated with order
  var isReturningFromCancellation = "false"  // we got back here due to a cancelled payment
  const phlebotomyHomeFee = 200;
  const [selectedPatient, setSelectedPatient] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [payMode, setPayMode] = useState(false);
  const [selectMode, setSelectMode] = useState(true);
  const [infoMode, setInfoMode] = useState(false);
  const [locationMode, setLocationMode] = useState(false);

  const [patientSearch, setPatientSearch] = useState(0);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [national_id, setNational_id] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [email_address, setEmail_address] = useState("");

  const [medaid_name, setMedaid_name] = useState("");
  const [medaid_number, setMedaid_number ] = useState("");
  const [medaid_plan, setMedaid_plan] = useState("");
  const [medaid_dep_code, setMedaid_dep_code] = useState("");

  const [formattedTotal, setFormattedTotal] = useState('');
  const [formattedVat, setFormattedVat] = useState('');
  const [formattedSubTotal, setFormattedSubTotal] = useState('');
  const [vatAmount, setVatAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  //Totals appearing while test selection occurs
  const [selectSubTotal, setSelectSubTotal] = useState(0);
  const [formattedSelectSubTotal, setFormattedSelectSubTotal] = useState("0");
  const [selectVatAmount, setSelectVatAmount] = useState(0);
  const [formattedSelectVatAmount, setFormattedSelectVatAmount] = useState("0");
  // const [selectTotal, setSelectTotal] = useState(0);
  const [formattedSelectTotal, setFormattedSelectTotal] = useState("0");
  const [phlebotomy_notes, setPhlebotomy_notes] = useState("");

  const [doctors_first_name, setDoctors_first_name] = useState("");
  const [doctors_surname, setDoctors_surname] = useState("");
  const [doctors_practice_name, setDoctors_practice_name] = useState("");
  const [doctors_province, setDoctors_province] = useState("");
  const [doctors_city, setDoctors_city] = useState("");
  const [doctors_location, setDoctors_location] = useState("");
  const [doctors_suburb, setDoctors_suburb] = useState("");
  const [doctors_telephone, setDoctors_telephone] = useState("");
  const [doctors_email_address, setDoctors_email_address] = useState("");
  const [doctors_practice_number, setDoctors_practice_number] = useState(""); // HPCSA Practice Number
  const [linked_practice_id, setLinked_practice_id] = useState(""); //internal id

  const [patientRows, setPatientRows] = useState({});
  const [doctorRows, setDoctorRows] = useState({});
  const { patients } = usePatientSearch(patientSearch,new Array(national_id,email_address,mobile_number));
  const { practiceDoctors } = usePracticeDoctors(profile.practice_id);
  const { practiceInfo } = usePracticeInfo(profile.practice_id);

  //comms preferences
  const [patientSMS, setPatientSMS ]= useState("false");
  const [patientWhatsapp, setPatientWhatsapp ]= useState("true");
  const [patientEmail, setPatientEmail] = useState("true");
  const [icd_10_code, setIcd_10_code] = useState("");

  const [doctorSMS, setDoctorSMS] = useState("false");
  const [doctorWhatsapp, setDoctorWhatsapp ]= useState("true");
  const [doctorEmail, setDoctorEmail] = useState("true");
  
  const [reportNotifyPrelim, setReportNotifyPrelim] = useState("false");
  const [reportNotifyAbnormal, setReportNotifyAbnormal] = useState("true");
  const [reportReleaseNormal, setReportReleaseNormal] = useState("true");
  const [reportReleasePeriod, setReportReleasePeriod] = useState("24");
  
  // newsearch
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchExternalResults, setSearchExternalResults] = useState([]);
  const fuseTests = new fuse(filteredTests.flatMap(category => category.data), {
    keys: ['long_name', 'tariff_code', 'category'], // Search by test name and tariff code
    includeScore: true, // Include score to sort results
    threshold: 0.3, // Adjust the threshold for matching sensitivity
  });

  const fuseExternalTests = new fuse(externalTests, {
    keys: ['long_name', 'tariff_code'],
    includeScore: true,
    threshold: 0.3,
  });

//  const [fuseExternalTests, setFuseExternalTests] = useState(null);
  const [pf_url, setPf_url] =  useState('https://www.payfast.co.za/eng/process');
  //const [pf_url, setPf_url] =  useState('https://sandbox.payfast.co.za/eng/process');

  // todo: check on secutiry implications of having these variables open in code
  const [pf_passPhrase, setPf_passPhrase] =  useState('Letsgoghostbusters9');
  const [pf_merchant_id, setPf_merchant_id] =  useState('23039424');
  const [pf_merchant_key, setPf_merchant_key] =  useState('fvysv1sdm5wsp'); 


  //  Deployed Version Payfast Variables
  /* const [pf_return_url, setPf_return_url] =  useState('https://ezpath-325415.web.app/checkout_return');
  const [pf_cancel_url, setPf_cancel_url] =  useState('https://ezpath-325415.web.app/checkout_cancel'); */
  
  
  const [pf_return_url, setPf_return_url] =  useState('https://ezpath.co.za/checkout_return');
  const [pf_cancel_url, setPf_cancel_url] =  useState('https://ezpath.co.za/checkout_cancel');
 /*  const [pf_return_url, setPf_return_url] =  useState('http://localhost:3000/checkout_return');
  const [pf_cancel_url, setPf_cancel_url] =  useState('http://localhost:3000/checkout_cancel'); */

  //  Local Version Payfast Variables //firebase emulator is port 8080
  //const [pf_return_url, setPf_return_url] =  useState('https://4fcd-169-0-25-41.ngrok-free.app/checkout_return'); //port 3000
  //const [pf_cancel_url, setPf_cancel_url] =  useState('https://4fcd-169-0-25-41.ngrok-free.app/checkout_cancel'); //port 3000
  const [pf_notify_url, setPf_notify_url] =  useState('https://us-central1-ezpath-325415.cloudfunctions.net/pfMessage?'); //port 5001
  // const client = axios.create({baseURL: "'https://us-central1-ezpath-325415.cloudfunctions.net"});
  
  const [pf_amount, setPf_amount] =  useState(0);
  const [pf_m_payment_id, setPf_m_payment_id] =  useState(0);
  const [pf_item_name, setPf_item_name] =  useState('EZPath_Test_Payment');
  const [pf_signature, setPf_signature] =  useState('');

  const md5 = require("md5");

//************PAYFAST INTEGRATION SECTION****************************//
//*******************************************************************//
//todo: move Payfast integration to it own .js file in /helpers/ folder.
function testSignature()
{ 
  const payForm = document.getElementById('payForm');

  if (Object.keys(payForm).length === 0) {
    return;
  }

  let result = '';

  const formElements = Array.from(payForm.elements);

  formElements.forEach(element => {
    if (element.id === 'pf_element') {
      var val = encodeURIComponent(element.value);
      val = val.replace(/\s/g, "+");
      result = result.concat(element.name+"="+val+"&");
    }   
  });

  if(pf_passPhrase !== null) 
  {
    result = result.concat("passphrase="+pf_passPhrase); 
  }
  
  var md5Hash = md5(result);
  setPf_signature(md5Hash);
  //  console.log("String to be hashed:", result);
  //  console.log("MD5 Hash:", md5Hash);
}
//************END OF PAYFAST INTEGRATION SECTION**********************//
//*******************************************************************//

const isValidMobileNumber = (number) => {
  const testNumber = number.replace(/\s/g, ''); // Remove spaces for the regex test

  const regex = /^0\s*[678]\d{1}\s*\d{3}\s*\d{4}$/;
  //return (testNumber === '' || regex.test(testNumber));
  return (regex.test(testNumber));
};

const isValidEmailAddress = (email) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //return (email === '' || regex.test(email));
  return (regex.test(email));
};


// newsearch
const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  if (query.length > 2) { 
    const results = fuseTests.search(query);
    const groupedResults = results.reduce((acc, result) => {
      const category = result.item.category;
      const item = result.item;

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    setSearchResults(groupedResults);
  } else {
    setSearchResults({}); // Clear results with an empty object
  }

  if (query.length > 2) { 
    const exresults = fuseExternalTests.search(query);
    setSearchExternalResults(exresults);
  } else {
    setSearchExternalResults({}); // Clear results with an empty object
  }
};
/* const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  if (query.length > 2) { // Start searching after at least 3 characters
    const results = fuseTests.search(query);
    let  i = results.map(result => result.item)
    setSearchResults(i);
  } else {
    setSearchResults([]);
  }
}; */
// **newsearch

useEffect (() => {
  let i = searchResults;
},[searchResults])

useEffect (() => {
  let i = searchExternalResults;
},[searchExternalResults])


const handleMobileChange = (e) => {
  const inputNumber = e.target.value;
  setMobile_number(inputNumber);
};

useEffect(() => {
  const isValidNumber = isValidMobileNumber(mobile_number);
  setIsValidNumber(isValidNumber);
}, [mobile_number]);


useEffect(() => {
  if (selectedDoctor) {
    setDoctorWhatsapp(selectedDoctor.comms_whatsapp?? "true");
    setDoctorEmail(selectedDoctor.comms_email?? "true");
    setDoctorSMS(selectedDoctor.comms_sms?? "false");
    setReportNotifyAbnormal(selectedDoctor.report_notify_abnormal?? "true");
    setReportNotifyPrelim(selectedDoctor.report_notify_prelim?? "false");
    setReportReleaseNormal(selectedDoctor.report_auto_release?? "true");
    setReportReleasePeriod(selectedDoctor.report_release_period?? "24");
  }
}, [selectedDoctor]);

const handleEmailChange = (e) => {
  const inputEmail = e.target.value;
  setEmail_address(inputEmail);
};

useEffect(() => {
  const emailValid = isValidEmailAddress(email_address);
  setIsValidEmail(emailValid);
}, [email_address]);


const handleBeforeUnload = (event) => {
  //  event.preventDefault();
  event.returnValue = '';
  localStorage.setItem("re-order-attempt", "false");
};


useEffect(() => {
  if (!orderId || orderId === "0" || orderId === "") {
    setFilteredTests(props.filteredTests.filteredData);
  }
  window.addEventListener('beforeunload', handleBeforeUnload);
  return () => window.removeEventListener('beforeunload', handleBeforeUnload);
}, []);

useEffect(() => {
  const getData = async () => {
    const data = await getExternalTests();
    setExternalTests(data);
  };

  getData();
}, []);


useEffect(() => {
//  let fuseExTests; // Declare fuse outside the if block
/* 
  if (externalTests) {
    fuseExTests = new fuse(externalTests, {
      keys: ['long_name', 'tariff_code'],
      includeScore: true,
      threshold: 0.3,
    });
    setFuseExternalTests(fuseExTests);
  } */
}, [externalTests]);


useEffect(() => {
  isReturningFromCancellation = localStorage.getItem("re-order-attempt") === "true";
  if(isReturningFromCancellation) {
    restoreOrderInfoFromLocalStorage();
  }
  else if (props.profile && props.profile.user_type === "patient") {
    setSelectedPatient(props.profile);
  }
}, []);


useEffect(() => {
  async function fetchIncompleteOrderData() {
    if (orderId && orderId !== "0" && orderId !== "") {
      setFilteredTests(props.filteredTests.filteredData);
      const orderInfo = await getIncompleteOrder(profile.practice_id, orderId);
      if (orderInfo !== null) {
        restoreOrderInfoFromDatabase(orderInfo);
      }
    }
  }

  fetchIncompleteOrderData(); // Call the async function immediately
}, [orderId]); // Run effect when orderId changes


useEffect(() => {
  if (profile.user_type === "practice") {
    setDoctorSMS(profile.comms_sms || "false");
    setDoctorEmail(profile.comms_email || "true");
    setDoctorWhatsapp(profile.comms_whatsapp || "true");

    setReportNotifyPrelim(profile.report_notify_prelim || "false");
    setReportNotifyAbnormal(profile.report_notify_abnormal || "true");
    setReportReleaseNormal(profile.report_auto_release || "true");
    setReportReleasePeriod(profile.report_release_period || "24");
  }

}, [profile]); 

const handleAddOnTestsChange = (newTests) => {
  setAddOnTests(newTests); 
};

function restoreOrderInfoFromLocalStorage() {
      const localStorageSelectedTests = localStorage.getItem('selectedTests');
      if (localStorageSelectedTests) {
        setRetrievedTests(JSON.parse(localStorageSelectedTests));
      }

      const localStorageSelectedSite = localStorage.getItem('selectedSite');
      if (localStorageSelectedSite) {
        setSelectedSite(JSON.parse(localStorageSelectedSite));
      }
    
      const localStorageOrderInfo = localStorage.getItem("orderInfo");
      if (localStorageOrderInfo && profile.user_type !== "practice") {
        const orderInfoObject = JSON.parse(localStorageOrderInfo);
        setName(orderInfoObject.name?? "");
        setSurname(orderInfoObject.surname?? "");
        setNational_id(orderInfoObject.national_id?? "");
        setMobile_number(orderInfoObject.mobile_number?? "");
        setEmail_address(orderInfoObject.email_address?? "");

        setMedaid_name(orderInfoObject.medaid_name?? "");
        setMedaid_number(orderInfoObject.medaid_number?? "");
        setMedaid_plan(orderInfoObject.medaid_plan?? "");
        setMedaid_dep_code(orderInfoObject.medaid_dep_code?? "");

        setDoctors_location(orderInfoObject.doctors_location?? "");
        setDoctors_first_name(orderInfoObject.doctors_first_name?? "");
        setDoctors_surname(orderInfoObject.doctors_surname?? "");
        setDoctors_practice_name(orderInfoObject.doctors_practice_name?? "");
        setLinked_practice_id(orderInfoObject.linked_practice_id?? "");
        setDoctors_practice_number(orderInfoObject.doctors_practice_number?? ""); //hpcsa reg.
        //linkeddoctorid??
        setDoctors_province(orderInfoObject.doctors_province?? "");
        setDoctors_city(orderInfoObject.doctors_city?? "");
        setDoctors_suburb(orderInfoObject.doctors_suburb?? "");
        setDoctors_telephone(orderInfoObject.doctors_telephone?? "");
        setDoctors_email_address(orderInfoObject.doctors_email_address?? "");
        setIcd_10_code(orderInfoObject.icd_10_code?? "");
        setPhlebotomy_notes(orderInfoObject.phlebotomy_notes?? "");
        setReportNotifyAbnormal(orderInfoObject.notify_abnormal?? "");
        setReportNotifyPrelim(orderInfoObject.notify_prelim?? "");
        setReportReleaseNormal(orderInfoObject.release_normal?? "");
        setReportReleasePeriod(orderInfoObject.release_normal_period?? "");
        setDoctorWhatsapp(orderInfoObject.notify_doctor_whatsapp?? "");
        setDoctorSMS(orderInfoObject.notify_doctor_sms?? "");
        setDoctorEmail(orderInfoObject.notify_doctor_email?? "");
        setPatientWhatsapp(orderInfoObject.notify_patient_whatsapp?? "");
        setPatientSMS(orderInfoObject.notify_patient_sms?? "");
        setPatientEmail(orderInfoObject.notify_patient_email?? "");
      }
}

function restoreOrderInfoFromDatabase(orderInfo) {
  if (orderInfo.selectedTests) {
    const retrieved_addon_tests = orderInfo.selectedTests.filter(test => test.lab === "999");
    const retrieved_external_tests = orderInfo.selectedTests.filter(test => test.lab === "998");
    const retrieved_tests = orderInfo.selectedTests.filter(test => test.lab !== "999" && test.lab !== "998");

    setRetrievedTests(retrieved_tests);
    setAddOnTests(retrieved_addon_tests);
    setRetrievedExternalTests(retrieved_external_tests);
    if (addOnTestRef.current) {
      addOnTestRef.current.setAddOnTests(retrieved_addon_tests); 
    }
  }

  if (orderInfo.selectedSite) {
    setSelectedSite(orderInfo.selectedSite);
  }

  if (orderInfo.orderInfo) {
    const orderInfoObject = JSON.parse(JSON.stringify(orderInfo.orderInfo));
    setName(orderInfoObject.name?? "");
        setSurname(orderInfoObject.surname?? "");
        setNational_id(orderInfoObject.national_id?? "");
        setMobile_number(orderInfoObject.mobile_number?? "");
        setEmail_address(orderInfoObject.email_address?? "");

        
        setMedaid_name(orderInfoObject.medaid_name?? "");
        setMedaid_number(orderInfoObject.medaid_number?? "");
        setMedaid_plan(orderInfoObject.medaid_plan?? "");
        setMedaid_dep_code(orderInfoObject.medaid_dep_code?? "");

        setDoctors_location(orderInfoObject.doctors_location?? "");
        setDoctors_first_name(orderInfoObject.doctors_first_name?? "");
        setDoctors_surname(orderInfoObject.doctors_surname?? "");
        setDoctors_practice_name(orderInfoObject.doctors_practice_name?? "");
        setLinked_practice_id(orderInfoObject.linked_practice_id?? "");
        // linkeddoctorid??
        setDoctors_province(orderInfoObject.doctors_province?? "");
        setDoctors_city(orderInfoObject.doctors_city?? "");
        setDoctors_suburb(orderInfoObject.doctors_suburb?? "");
        setDoctors_telephone(orderInfoObject.doctors_telephone?? "");
        setDoctors_email_address(orderInfoObject.doctors_email_address?? "");
        setDoctors_practice_number(orderInfoObject.doctors_practice_number?? "");
        setIcd_10_code(orderInfoObject.icd_10_code?? "");
        setPhlebotomy_notes(orderInfoObject.phlebotomy_notes?? "");
        setReportNotifyAbnormal(orderInfoObject.notify_abnormal?? "");
        setReportNotifyPrelim(orderInfoObject.notify_prelim?? "");
        setReportReleaseNormal(orderInfoObject.release_normal?? "");
        setReportReleasePeriod(orderInfoObject.release_normal_period?? "");
        setDoctorWhatsapp(orderInfoObject.notify_doctor_whatsapp?? "");
        setDoctorSMS(orderInfoObject.notify_doctor_sms?? "");
        setDoctorEmail(orderInfoObject.notify_doctor_email?? "");
        setPatientWhatsapp(orderInfoObject.notify_patient_whatsapp?? "");
        setPatientSMS(orderInfoObject.notify_patient_sms?? "");
        setPatientEmail(orderInfoObject.notify_patient_email?? "");
  }
}


useEffect(() => {

   if (profile.user_type === "patient" && !isReturningFromCancellation) {
 /*      setName(profile.name);
      setSurname(profile.surname);
      setNational_id(profile.national_id);
      setMobile_number(profile.mobile_number);
      setEmail_address(profile.email_address); */
      setSelectedPatient(profile);
    }
    if (profile.user_type === "practice") {
      //setDoctors_location("");
      setDoctors_first_name(profile.name);
      setDoctors_surname(profile.surname);
      // setDoctors_practice_name("");
    }

}, [profile, isReturningFromCancellation]);


useEffect(() => {

  if (isReturningFromCancellation) {
    return;  // use the info in local storage rather
  }

  if (selectedPatient.name) {  // There is something there
    setName(selectedPatient.name);
    setSurname(selectedPatient.surname);
    setNational_id(selectedPatient.national_id);
    setMobile_number(selectedPatient.mobile_number);
    setEmail_address(selectedPatient.email_address);
  }
  else {
    setName("");
    setSurname("");
    setNational_id("");
    setMobile_number("");
    setEmail_address("");
  }
}, [selectedPatient] )


function orderTestsClick() {  
  window.scrollTo(0, 0); //go to the top when we access this section of the page        
  setPayMode(true);
  setSelectMode(false);
  setInfoMode(false);
  setLocationMode(false);
}

function selectTestsClick() {  
  window.scrollTo(0, 0); 
  setPatientRows({}); //rather use the input boxes     
  setPayMode(false);
  setSelectMode(true);
  setInfoMode(false);
  setLocationMode(false);
}

async function prepareOrderArray() {
  var tempTests = [];
  var tempPrice = 0.00;
  var tempVatAmount = 0.00;

  // setCheckboxesChecked([]);
  var checkedBoxes = [];
  var checkboxes = document.getElementsByName('testBox');
  // loop over all the checkboxes
  for (var i=0; i<checkboxes.length; i++) {
      // And push the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkedBoxes.push(checkboxes[i].id);
      }
  }

  for (var index1 = 0; index1 < filteredTests.length; index1++) {
    for (var index2 = 0; index2 < filteredTests[index1].data.length; index2++) {
      for(var index3 = 0; index3 < checkedBoxes.length; index3++) {
          if (filteredTests[index1].data[index2].id === checkedBoxes[index3])
          {
            tempTests.push(filteredTests[index1].data[index2]);
            tempPrice = tempPrice + parseFloat(filteredTests[index1].data[index2].reduced_price);
            tempVatAmount = tempVatAmount + parseFloat(filteredTests[index1].data[index2].vat_amount);
          }
      }
    }
  }

/*   if (selectedSite.site_id === "4") { // home phlebotomy service selected
    tempPrice = tempPrice + phlebotomyHomeFee;
   } */

  // smsm! setSelectedTests([...tempTests]); //already set
/*   setPf_amount(tempPrice + tempVatAmount);   
  setVatAmount(tempVatAmount);
  setSubTotal(tempPrice);
 */
  setPf_amount(selectSubTotal + selectVatAmount);   
  setVatAmount(selectVatAmount);
  setSubTotal(selectSubTotal);

  try {
    // Call the function to get the invoice number
    const invoiceNumber =  getInvoiceNumber();
    setPf_m_payment_id(invoiceNumber);
  } catch (error) {
    console.error("Error generating or retrieving invoice number:", error);
    setPf_m_payment_id("AAA-000-AAA");  // Default ID to be corrected manually later
  }
}

  // Call the function and await the result
async function generateNewInvoice() {
  try {
    const invoiceNumber = getInvoiceNumber();
    console.log("New invoice number:", invoiceNumber);
    // Now you can use the invoice number for further processing
  } catch (error) {
    console.error("Error generating new invoice number:", error);
  }
}

  
useEffect(() => {
  if (retrievedTests.length > 0 && filteredTests.length > 0) {
    const tempFilteredTests = [...filteredTests];

    for (let index2 = 0; index2 < tempFilteredTests.length; index2++) {
      let isSelected = false; // Track if any subobject is selected

      for (let index3 = 0; index3 < tempFilteredTests[index2].data.length; index3++) {
        if (retrievedTests.some(rt => rt.id === tempFilteredTests[index2].data[index3].id)) {
          isSelected = true;
          tempFilteredTests[index2].data[index3].selected = true;
          // No need to break here, keep checking for other selected subobjects
        } 
        //  else { 
        //    tempFilteredTests[index2].data[index3].selected = false; // Optional: Ensure other subobjects are not selected
        //  }
      }

      tempFilteredTests[index2].selected = isSelected; // Set the parent "selected" based on the flag
    }

    setFilteredTests(tempFilteredTests);
  }
}, [retrievedTests]);


useEffect(() => {
  if (retrievedExternalTests.length > 0 && externalTests.length > 0) {
    const tempExternalTests = [...externalTests];

    for (let index = 0; index < tempExternalTests.length; index++) {
      if (retrievedExternalTests.some(rt => rt.id === tempExternalTests[index].id)) {
          tempExternalTests[index].selected = true;
          tempExternalTests[index].lab = "998";
        } 
    }
    setExternalTests(tempExternalTests);
  }
}, [retrievedExternalTests]);



const downloadCSV = (event) => {
  event.preventDefault(); // Prevent default behavior

  // Create a comma-delimited string from the testSet data
  const csvContent = "data:text/csv;charset=utf-8,"
    + "Tariff Code,Test Category,Test Name,Price (ex. VAT),VAT,Amount\n"
    + selectedTests.map(item => {
      const tariffCode = item.tariff_code ? `"${item.tariff_code}"` : '';
      const reducedPrice = item.reduced_price ? parseFloat(item.reduced_price.replace(',', '.')) : 0;
      const vatAmount = item.vat_amount ? parseFloat(item.vat_amount.replace(',', '.')) : 0;
      const amount = (reducedPrice + vatAmount);
  
      // Format numbers with .00 if needed
      const formatNumber = (num) => num.toLocaleString('en-ZA', { minimumFractionDigits: 2, maximumFractionDigits: 2 }); 
  
      return `${tariffCode},${item.category},${item.long_name},"${formatNumber(reducedPrice)}","${formatNumber(vatAmount)}","${formatNumber(amount)}"`; 
    }).join('\n');

  const fileName = "quote_"+name+"_"+surname;

  // Create a hidden download link
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".csv");
  document.body.appendChild(link);

  // Trigger the download
  link.click();
  document.body.removeChild(link);
};


function prepareCheckout() {

    const filteredTestsWithSelected = filteredTests
    .map(categoryObj => ({
      ...categoryObj,
      data: categoryObj.data.filter(test => test.selected)
    }))
    .filter(categoryObj => categoryObj.data.length > 0);
  
  // Assuming newTests have the same structure as the objects in categoryObj.data
  addOnTests.forEach(addOnTest => {
    const category = filteredTestsWithSelected.find(categoryObj => categoryObj.name === addOnTest.category); 
  
    if (category) {
      category.data.push(addOnTest); 
    } else {
      // Create a new category if it doesn't exist
      filteredTestsWithSelected.push({ 
        category: addOnTest.category, 
        data: [addOnTest] 
      });
    }
  });

  if (externalTests) {
    const filteredExTests = externalTests.filter(test => test.selected); // Simplified condition

    if (filteredExTests.length) { // More concise check
      const ft = {category: filteredExTests[0].category || 'Non-EZPath Tests', data: filteredExTests};
      filteredTestsWithSelected.push(ft);
    }
  }
    

  setCheckoutTests(filteredTestsWithSelected);
  const allTests = filteredTestsWithSelected.flatMap(categoryObj => categoryObj.data);
  setSelectedTests(allTests); 

  setPf_amount(selectSubTotal + selectVatAmount);   
  setVatAmount(selectVatAmount);
  setSubTotal(selectSubTotal);

  try {
    // Call the function to get the invoice number
    const invoiceNumber =  getInvoiceNumber();
    setPf_m_payment_id(invoiceNumber);
  } catch (error) {
    console.error("Error generating or retrieving invoice number:", error);
    setPf_m_payment_id("AAA-000-AAA");  // Default ID to be corrected manually later
  }
}

  function payTestsClick() {
    //todo: insert Payfast stuff here
    //write transaction data to the order_attempt collection in the database
    window.scrollTo(0, 0);
    setPayMode(true);
    setSelectMode(false);
    setInfoMode(false);
    setLocationMode(false);
  }

  function testsBackClick() {
    setPayMode(false);
    setSelectMode(false);
    setInfoMode(true);
    setLocationMode(false);
    window.scrollTo(0, 0);
  }
  

function assembleOrderInfo(id){
  var info = ({});

  const timestamp = new Date();
  info.timestamp = timestamp;
  if (profile.user_id === undefined || profile.user_id === null) {
    //info.user_id = "unregistered-user";
    info.ordered_by = "unregistered-user"; 
    info.ordered_by_name = "unregistered-"; 
    info.ordered_by_surname = "user"; 
  } else {
    //info.user_id = profile.user_id;
    info.ordered_by = profile.user_id;
    info.ordered_by_name = profile.name;
    info.ordered_by_surname = profile.surname;
    // info.user_id = profile.user_id;
    // info.ordered_by_org = profile.practice_id;
  }
  if (selectedPatient?.user_id) {
    info.user_id = selectedPatient.user_id;
  }
  /* if (profile.user_type === "practice") {
    info.linked_practice_id = profile.practice_id;
    info.linked_doctor_id = profile.user_id;
  } */
 if (profile.user_type === "lab") {
    info.linked_lab_id = profile.practice_id;
    //info.linked_doctor_id = profile.user_id;
  }
  info.status = (id === "paynowform") ? "attempted" : "ordered";
  info.phlebotomyHomeFee = (selectedSite.site_id === "4") ? "200" : "0";
  info.name = name;
  info.surname = surname;
  info.national_id = national_id;
  info.mobile_number = mobile_number;
  info.email_address = email_address;   
  info.icd_10_code = icd_10_code;   
  info.medaid_name = medaid_name;
  info.medaid_number = medaid_number;
  info.medaid_plan = medaid_plan;
  info.medaid_dep_code = medaid_dep_code;
  info.phlebotomy_notes = phlebotomy_notes;

  if (Object.keys(selectedDoctor).length !== 0)
  {
    info.doctors_first_name = selectedDoctor.name;
    info.doctors_surname = selectedDoctor.surname;
    info.linked_doctor_id = selectedDoctor.user_id;
    info.linked_practice_id = selectedDoctor.practice_id;
    info.doctors_practice_name = practiceInfo.practice_name;
    info.doctors_practice_number = selectedDoctor.practice_number;
    info.doctors_email_address = selectedDoctor.email_address;
    info.doctors_telephone = practiceInfo.practice_telephone;
  }
  else {
    info.doctors_first_name = doctors_first_name;
    info.doctors_surname = doctors_surname;
    info.doctors_location = doctors_location;
    info.doctors_practice_name = doctors_practice_name;
    info.doctors_practice_number = doctors_practice_number;
    info.doctors_suburb = doctors_suburb;
    info.doctors_city = doctors_city;
    info.doctors_province = doctors_province;
    info.doctors_email_address = doctors_email_address;
    info.doctors_practice_number = doctors_practice_number;
    info.doctors_telephone = doctors_telephone;
  }

  info.notify_patient_sms = patientSMS;
  info.notify_patient_whatsapp = patientWhatsapp;
  info.notify_patient_email = patientEmail;

  info.notify_doctor_whatsapp = doctorWhatsapp;
  info.notify_doctor_sms = doctorSMS;
  info.notify_doctor_email = doctorEmail;
  info.notify_prelim = reportNotifyPrelim;
  info.notify_abnormal = reportNotifyAbnormal;
  info.release_normal = reportReleaseNormal;
  info.release_normal_period = reportReleasePeriod;

  if (orderId && orderId !== "0" && orderId !== "") {
    info.order_id = orderId;
  }
  return info;
}

  const payClick = (event) => {

    // the info variable is here because patients, even if logged in, may change order details on the form
    // or a doctor or phlebotomist may be ordering on behalf of a patient
    testSignature();
    
    const id = event.currentTarget.id;
    const info = assembleOrderInfo(id);
    setOrderInfo(info);

    var items = [];
    var orderIndex = "";

    for (var index1 = 0; index1 < selectedTests.length; index1++) {

      if (selectedTests.length === 1) {
        orderIndex = "single";
    } else if (index1 === (selectedTests.length - 1)) { 
        orderIndex = "end"; // set to "end" if last entry
    } else {
      orderIndex = index1.toString();
    }

        var test = {
          m_payment_id: pf_m_payment_id,
          yoco_id: pf_m_payment_id,
          order_index: orderIndex,
          ordered_date: info.timestamp,
          status: info.status,
          ordered_by: info.ordered_by,
          ordered_by_name: info.ordered_by_name,
          ordered_by_surname: info.ordered_by_surname,
          test_category: selectedTests[index1].category,
          test_name: selectedTests[index1].long_name,
          tariff_code: selectedTests[index1].tariff_code,
          reduced_price: selectedTests[index1].reduced_price,
          vat_amount: selectedTests[index1].vat_amount,
          lab: selectedTests[index1].lab,
          order_vat: formattedVat,
          order_subtotal: formattedSubTotal,
          order_total: formattedTotal,
          patient_name: info.name,
          patient_surname: info.surname,
          patient_national_id: info.national_id,
          user_id: info.user_id,
          mobile_number: info.mobile_number,
          email_address: info.email_address,
          doctors_first_name: info.doctors_first_name,
          doctors_surname: info.doctors_surname,
          doctors_location: info.doctors_location,
          doctors_practice_name: info.doctors_practice_name,
          doctors_practice_number: info.doctors_practice_number,
          doctors_suburb: info.doctors_suburb,
          doctors_city: info.doctors_city,
          doctors_province: info.doctors_province,
          doctors_telephone: info.doctors_telephone,
          linked_practice_id: info.linked_practice_id,
          linked_doctor_id: info.linked_doctor_id,
          phlebotomy_site: selectedSite.site_id, 
          phlebotomy_site_name: selectedSite.site_name,
          phlebotomy_fee: info.phlebotomyHomeFee,
          notify_patient_email: info.notify_patient_email,
          notify_patient_sms: info.notify_patient_sms,
          notify_patient_whatsapp: info.notify_patient_whatsapp,
          notify_doctor_whatsapp: info.notify_doctor_whatsapp,
          notify_doctor_sms: info.notify_doctor_sms,
          notify_doctor_email: info.notify_doctor_email,
          notify_prelim: info.notify_prelim,
          notify_abnormal: info.notify_abnormal,
          release_normal:info.release_normal,
          release_normal_period: info.release_normal_period,
          icd_10_code: info.icd_10_code,
          order_id: info.order_id,
          phlebotomy_notes: info.phlebotomy_notes,
          linked_lab_id: profile.practice_id,
          medaid_name: info.medaid_name,
          medaid_number: info.medaid_number,
          medaid_plan: info.medaid_plan,
          medaid_dep_code: info.medaid_dep_code,
        };

        // Remove properties with undefined values
        test = Object.fromEntries(
          Object.entries(test).filter(([key, value]) => value !== undefined)
        );
          items.push(test);
      }

    //const item_object = {items};
    const order_data = {items};
  
    localStorage.setItem('selectedTests', JSON.stringify(selectedTests));
    localStorage.setItem('orderInfo', JSON.stringify(info));
    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
    
    if (items[0]) {
      localStorage.setItem('testItems', JSON.stringify(items[0]));
    }

    if (id === "PayNowForm"){
      if (writeIntendedOrder(order_data) === false){
        event.preventDefault();
        alert("intended order failed due to database connectivity, please try again"); 
        setPf_m_payment_id(getInvoiceNumber()); //reset the payment id in case some database writes were successful, this will be seen as a new order
        return; 
      }
    } 
    else if  (id === "PayLaterButton") {
      if (writePayLaterOrder(order_data) === false){
        event.preventDefault();
        alert("intended order failed due to database connectivity, please try again"); 
        return; 
      } else {
        history.push(ROUTES.CHECKOUT_PAY_LATER);
      } 
    } 

    setPayMode(true);
    setSelectMode(false);
    setInfoMode(false);
    setLocationMode(false);
    window.scrollTo(0, 0);
  }


  const handleSaveClick = async (event) => {
    const id = event.currentTarget.id;
    const info = assembleOrderInfo(id);
    setOrderInfo(info);

    var items = [];
    var orderIndex = "";

    for (var index1 = 0; index1 < selectedTests.length; index1++) {

      if (selectedTests.length === 1) {
        orderIndex = "single";
    } else if (index1 === (selectedTests.length - 1)) { 
        orderIndex = "end"; // set to "end" if last entry
    } else {
      orderIndex = index1.toString();
    }

        var test = {
          m_payment_id: pf_m_payment_id,
          yoco_id: pf_m_payment_id,
          order_index: orderIndex,
          ordered_date: info.timestamp,
          status: info.status,
          ordered_by: info.ordered_by,
          ordered_by_name: info.ordered_by_name,
          ordered_by_surname: info.ordered_by_surname,
          test_category: selectedTests[index1].category,
          test_name: selectedTests[index1].long_name,
          lab: selectedTests[index1].lab,
          tariff_code: selectedTests[index1].tariff_code,
          reduced_price: selectedTests[index1].reduced_price,
          vat_amount: selectedTests[index1].vat_amount,
          order_vat: formattedVat,
          order_subtotal: formattedSubTotal,
          order_total: formattedTotal,
          patient_name: info.name,
          patient_surname: info.surname,
          patient_national_id: info.national_id,
          user_id: info.user_id,
          mobile_number: info.mobile_number,
          email_address: info.email_address,
          doctors_first_name: info.doctors_first_name,
          doctors_surname: info.doctors_surname,
          doctors_location: info.doctors_location,
          doctors_practice_name: info.doctors_practice_name,
          doctors_practice_number: info.doctors_practice_number,
          doctors_suburb: info.doctors_suburb,
          doctors_city: info.doctors_city,
          doctors_province: info.doctors_province,
          doctors_telephone: info.doctors_telephone,
          linked_practice_id: info.linked_practice_id,
          linked_doctor_id: info.linked_doctor_id,
          phlebotomy_site: selectedSite.site_id, 
          phlebotomy_site_name: selectedSite.site_name,
          phlebotomy_fee: info.phlebotomyHomeFee,
          notify_patient_email: info.notify_patient_email,
          notify_patient_sms: info.notify_patient_sms,
          notify_patient_whatsapp: info.notify_patient_whatsapp,
          notify_doctor_whatsapp: info.notify_doctor_whatsapp,
          notify_doctor_sms: info.notify_doctor_sms,
          notify_doctor_email: info.notify_doctor_email,
          notify_prelim: info.notify_prelim,
          notify_abnormal: info.notify_abnormal,
          release_normal:info.release_normal,
          release_normal_period: info.release_normal_period,
          icd_10_code: info.icd_10_code,
          phlebotomy_notes: info.phlebotomy_notes,
          medaid_name: info.medaid_name,
          medaid_number: info.medaid_number,
          medaid_plan: info.medaid_plan,
          medaid_dep_code: info.medaid_dep_code,
        };

        // Remove properties with undefined values
        test = Object.fromEntries(
          Object.entries(test).filter(([key, value]) => value !== undefined)
        );
          items.push(test);
      }

    const order_data = {items};
      if (await writeIncompleteOrder(profile.practice_id, selectedTests, info, selectedSite, items, orderId))
        alert("The test order was successfully saved");
      else
        alert("There was a problem saving the test order, please contact your administrator");
   /*  localStorage.setItem('selectedTests', JSON.stringify(selectedTests));
    localStorage.setItem('orderInfo', JSON.stringify(info));
    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
    
    if (items[0]) {
      localStorage.setItem('testItems', JSON.stringify(items[0]));
    }
 */
    

   /*  setPayMode(false);
    setSelectMode(false);
    setInfoMode(false);
    setLocationMode(false); */
    history.goBack();
    //window.scrollTo(0, 0);
  }

/*   function storeTests() {
    var tempCheckboxes = [];

    setCheckboxesChecked([]);
    var checkboxes = document.getElementsByName('testBox');
    // loop over all the checkboxes
    for (var i=0; i<checkboxes.length; i++) {
        // And push the checked ones onto an array...
        if (checkboxes[i].checked) {
          tempCheckboxes.push(checkboxes[i].id);
        }
    }
    setCheckboxesChecked(tempCheckboxes);
  } */


  function isTestSelected(id) {
    for (var index=0; index < selectedTests.length; index++) {
      if (id === selectedTests[index].id) {
        return true;
      }
    }
    return false;
  }


  function rememberTests()
  {
    if (addOnTestRef.current) {
      addOnTestRef.current.setAddOnTests(addOnTests); 
    }
 /*    var checkboxes = document.getElementsByName('testBox');
    if (checkboxes.length === testCount &&
    selectedTests.length > 0) {
      // loop over all the checkboxes
      for (var index1 =0; index1 <checkboxes.length; index1++) {
        for (var index2=0; index2 < selectedTests.length; index2++) {
          if (checkboxes[index1].id === selectedTests[index2].id) {
            checkboxes[index1].checked = true;
            
            var header = document.getElementsByName(selectedTests[index2].category);
            if (Object.keys(header).length !== 0) 
              {header[0].style.background = 'rgba(200,100,20,0.50)';}
          }  
        }
      }
    } */
  }

  function rememberSite()
  {
    var radioInputs = document.getElementsByName('selectSite');

      // loop over all the site selection inputs
    for (var index =0; index <radioInputs.length; index++) {
 
        if (radioInputs[index].id === selectedSite.site_id) {
          radioInputs[index].checked = true;   
        }   
    }
  }

  function rememberOrderInfo()
  {
    var radioInputs = document.getElementsByName('selectSite');

      // loop over all the site selection inputs
    for (var index =0; index <radioInputs.length; index++) {
 
        if (radioInputs[index].id === selectedSite.site_id) {
          radioInputs[index].checked = true;   
        }   
    }
    
  var sms = document.getElementById('sms');
  var whatsapp = document.getElementById('whatsapp');
  var email = document.getElementById('email');
  var doc_sms = document.getElementById('doc-sms');
  var doc_whatsapp = document.getElementById('doc-whatsapp');
  var doc_email = document.getElementById('doc-email');


  if (sms) sms.checked = (patientSMS === "true");
  if (whatsapp) whatsapp.checked = (patientWhatsapp === "true");
  if (email) email.checked = (patientEmail === "true");
  if (doc_sms) doc_sms.checked = (doctorSMS === "true");
  if (doc_whatsapp) doc_whatsapp.checked = (doctorWhatsapp === "true");
  if (doc_email) doc_email.checked = (doctorEmail === "true");
  }

  function parseLocaleFloat(numberString) {
    try {
      // Check if the input is already a number
      if (typeof numberString !== 'string') {
        return numberString; 
      }
  
      // Replace commas with periods to standardize the format
      const standardizedNumberString = numberString.replace(',', '.'); 
      const parsedNumber = parseFloat(standardizedNumberString);
  
      // Check if the parsed value is a valid number
      if (isNaN(parsedNumber)) {
        console.error("Invalid number format:", numberString);
        return 0; // Or handle the error in another way, like throwing an exception
      }
  
      return parsedNumber;
  
    } catch (error) {
      console.error("Error parsing number:", numberString, error);
      return 0; // Or handle the error appropriately
    }
  }
  
useEffect(() => {
  var subTotal = 0.00;
  var vatAmount = 0.00;


  if (filteredTests.length > 0) {     
    const selectedTests = filteredTests.flatMap(obj => obj.data).filter(test => test.selected === true);

    for (var index = 0; index < selectedTests.length; index++) {
      subTotal = subTotal + parseLocaleFloat(selectedTests[index].reduced_price);
      vatAmount = vatAmount + parseLocaleFloat(selectedTests[index].vat_amount);
    }
  }

  if (addOnTests.length > 0) {     
    
    for (var index = 0; index < addOnTests.length; index++) {
      if (!isNaN(addOnTests[index].reduced_price)) {
        const reduced_price = parseLocaleFloat(addOnTests[index].reduced_price);
        subTotal = subTotal + reduced_price;
        const vat = parseLocaleFloat(addOnTests[index].vat_amount);
        vatAmount = vatAmount + vat;
      }
    }
  }

  if (externalTests && externalTests.length > 0) {     
    const selectedExTests = externalTests.filter(test => test.selected === true);
    
    if (selectedExTests) {
      for (var index = 0; index < selectedExTests.length; index++) {
        if (!isNaN(selectedExTests[index].reduced_price)) {
          const reduced_price = parseLocaleFloat(selectedExTests[index].reduced_price);
          subTotal = subTotal + reduced_price;
          const vat = parseLocaleFloat(selectedExTests[index].vat_amount);
          vatAmount = vatAmount + vat;
        }
      }
    } 
  }

  setSelectSubTotal(subTotal);
  setSelectVatAmount(vatAmount);
  setFormattedSelectTotal(`${formatNumberWithSpaceSeparator(subTotal + vatAmount,2)}`);

}, [filteredTests, category, addOnTests, externalTests]);



useEffect(() => {
  let combinedArray = [];

  for (const item in patients) {
    const subObject = patients[item];
    combinedArray.push(subObject);
    }

  setPatientRows(combinedArray);
}, [patients]);


useEffect(() => {

  if (practiceDoctors != null)
  {
    const foundObject = practiceDoctors.find(obj => obj.user_id === profile.user_id) 
    if (typeof foundObject !== "undefined") {
        setSelectedDoctor(foundObject);
        //orderInfoLoc.linked_patient_id = foundObject.user_id;
    }
  }
}, [practiceDoctors]);

useEffect(() => {
  if (selectMode === true) {
    rememberTests();
  }
  else if (infoMode == true) {
    rememberOrderInfo();
  }
  else if (locationMode == true) {
    rememberSite();
  }
  else {
    //testSignature();
  }
}, [selectMode, locationMode, infoMode]);


/*   const patientCommsCheckboxChange = (event) => {
    const channel = event.target.name;
    const isChecked = event.target.checked; // Get the actual checked state

    if (channel === "sms") {
        setPatientSMS(isChecked); // Update patientSMS state
    } else if (channel === "whatsapp") { 
         setPatientWhatsapp(isChecked); // Update patientWhatsApp state
    } else if (channel === "email") {
        setPatientEmail(isChecked); // Update patientEmail state    
    }
} */

    
const handleCommsBoxChange = (event) => {
  const { id, checked } = event.target; 
  if (id === "doctor-sms") {
    setDoctorSMS(checked.toString());
  }
  else if (id === "doctor-email") {
    setDoctorEmail(checked.toString());
  }
  else if (id === "doctor-whatsapp") {
    setDoctorWhatsapp(checked.toString());
  }
  else if (id === "doctor-notify-prelim") {
    setReportNotifyPrelim(checked.toString());
  }
  else if (id === "doctor-notify-abnormal") {
    setReportNotifyAbnormal(checked.toString());
  }
  else if (id === "release-results-normal") {
    setReportReleaseNormal(checked.toString());
  }
  else if (id === "patient-sms") {
    setPatientSMS(checked.toString());
  }
  else if (id === "patient-whatsapp") {
    setPatientWhatsapp(checked.toString());
  }
  else if (id === "patient-email") {
    setPatientEmail(checked.toString());
  }   
};
  


const handleCheckboxChange = (event) => {

  var clicked_test = {};
  var tempFilteredTests = [...filteredTests];
 
  for (var i = 0; i < tempFilteredTests.length; i++) {
    clicked_test = tempFilteredTests[i].data.find(test => test.id === event.target.id);
    if (clicked_test != null) {
      clicked_test.selected = event.target.checked;
      tempFilteredTests[i].selected = false; //default to false (this is for the category)
        for (var index = 0; index < tempFilteredTests[i].data.length; index++) {
          if (tempFilteredTests[i].data[index].selected === true) {
            tempFilteredTests[i].selected = true;
              break;
          }
        }
      }
    }
    setFilteredTests(tempFilteredTests);
}

const handleExternalCheckboxChange = (event) => {
  var clicked_test = {};
  var tempExternalTests = [...externalTests];
 
  for (var i = 0; i < tempExternalTests.length; i++) {
    clicked_test = tempExternalTests.find(test => test.id === event.target.id);
    if (clicked_test != null) {
          clicked_test.selected = event.target.checked; 
          clicked_test.lab = "998";
          break;
      }
    }
    setExternalTests(tempExternalTests);
};

  const handleRadioInputChange = (event) => {

    var tempSiteID = event.target.id;
    
    if (tempSiteID !== null)
    {
      //setSelectedSite(tempSiteID); //smsmD
      getSiteData(tempSiteID)
      .then((siteResult) => {
        if (siteResult.length > 0) {
        setSelectedSite(siteResult[0]);
        }
      })
      .catch((errorMessage) => {
        setError(errorMessage);
      });
    }
  }

  function handleBackClick() {  
    if (selectMode == true) {
      history.goBack();
    }
    else if (locationMode == true) {
      setSelectMode(true);
      setInfoMode(false);
      setPayMode(false);
      setLocationMode(false);
      window.scrollTo(0,0);
    }
    else if (infoMode == true) {
      setSelectMode(false);
      setInfoMode(false);
      setPayMode(false);
      setLocationMode(true);
      window.scrollTo(0, 0);
    }
    else if (payMode == true) {
      setSelectMode(false);
      setInfoMode(true);
      setPayMode(false);
      setLocationMode(false);
      window.scrollTo(0, 0);
    }
  }

  const findPatients = (event) => {
    event.preventDefault();
      setPatientRows({});
      // setLoading(true);
      setPatientSearch(patientSearch+1);
  }

  const enterDetailsClick = (event) => {
    //event.preventDefault();

    setSelectMode(false);
    setInfoMode(true);
    setPayMode(false);
    setLocationMode(false);
    window.scrollTo(0, 0);
  }


  const selectPatientClick = (event) => {
    event.preventDefault();
    if (event.target.id === selectedPatient.user_id) {  //unselect
      setSelectedPatient({});
    }
    else if (event.target.id !== "") {
      const foundObject = patientRows.find(obj => obj.user_id === event.target.id) 
      if (typeof foundObject !== "undefined") {
          setSelectedPatient(foundObject);
          //orderInfoLoc.linked_patient_id = foundObject.user_id;
      }
    }
  }

  const selectDoctorClick = (event) => {
    event.preventDefault();
    if (event.target.id === selectedDoctor.user_id) {  //unselect
      setSelectedDoctor({});
    }
    else if (event.target.id !== "") {
      const foundObject = practiceDoctors.find(obj => obj.user_id === event.target.id) 
      if (typeof foundObject !== "undefined") {
          setSelectedDoctor(foundObject);
          //orderInfoLoc.linked_patient_id = foundObject.user_id;
      }
    }
  }


  const selectLocationClick = (event) => {
    prepareCheckout();
    // prepareOrderArray();
    setSelectMode(false);
    setInfoMode(false);
    setPayMode(false);
    setLocationMode(true);
    window.scrollTo(0, 0); //go to the top when we access this section of the page
  }

  // Format the total amount with a space separator
  function formatNumberWithSpaceSeparator(number, decimals) {
    //number = number.replace(/\s/g, "");
    var decimalString = parseFloat(number).toFixed(decimals); //change the decimal places (cents value) here
    return decimalString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  

  useEffect(() => {
    if (!isNaN(subTotal) || subTotal !== null) {
      //  Set the formatted total amount
      //  setFormattedTotal(`${formatNumberWithSpaceSeparator(pf_amount*1,15)}`);
      //  setFormattedSubTotal(`${formatNumberWithSpaceSeparator(subTotal,2)}`);
      //let vat = subTotal*0.15;
      //vat = parseFloat(vat.toFixed(2))
      //setVatAmount(vat);
      setSelectSubTotal(subTotal);  // necessary if we return from a cancelled order

    }
  }, [subTotal]);


  useEffect(() => {
    if (!isNaN(selectSubTotal) || selectSubTotal !== null) {
      setFormattedSelectSubTotal(formatNumberWithSpaceSeparator(parseFloat(selectSubTotal),2));
    }
  }, [selectSubTotal]);


  useEffect(() => {
    if (!isNaN(selectVatAmount) || selectVatAmount !== null) {
      setFormattedSelectVatAmount(formatNumberWithSpaceSeparator(parseFloat(selectVatAmount),2));
    }
  }, [selectVatAmount]);



  useEffect(() => {
    if (vatAmount){
      setFormattedVat(`${formatNumberWithSpaceSeparator(vatAmount,2)}`);
      setFormattedTotal(`${formatNumberWithSpaceSeparator(subTotal + vatAmount,2)}`);
      setFormattedSubTotal(`${formatNumberWithSpaceSeparator(subTotal,2)}`);
      // setPf_amount(subTotal + vatAmount);
      
      setSelectVatAmount(vatAmount);
      setFormattedSelectTotal(`${formatNumberWithSpaceSeparator(subTotal + vatAmount,2)}`);
    }
  }, [vatAmount]);

  useEffect(() => {
    if (pf_amount){
      // testSignature();  //  generate a new signature whenever the amount to be paid changes
    }
  }, [pf_amount]);


  const handleSummaryDownload = async () => {
    const info = assembleOrderInfo("");
    //setOrderInfo(info);
    createOrderSummaryForm(info, selectedTests, formattedSelectSubTotal, formattedSelectVatAmount, formattedSelectTotal, selectedSite.site_id);
};

const handleDirectionsDownload = async () => {
  const info = assembleOrderInfo("");
  //setOrderInfo(info);
  createSiteDirections(info);
};



  return ( 
    <>
    <script src="js/md5.min.js"></script>
    
    { infoMode ?
      <>
        <AccordionTests>
        <OptForm.ModestButton onClick={handleSaveClick} >Save & Exit</OptForm.ModestButton>
          <OptForm.BackButton onClick={() => handleBackClick()} >&lt; Back to Select Location</OptForm.BackButton>
          
          {/* {profile.user_type !== "practice"? 
            <OptForm.LeftText onClick={handleSaveClick} >Save and Exit</OptForm.LeftText>:
            <></>} */}
          <AccordionTests.Title>Patient Details:</AccordionTests.Title>
          {error && <Form.Error data-testid="error">{error}</Form.Error>}
          <Form.Base onSubmit={findPatients} method="POST">
                <Form.Input
                  placeholder="Name"
                  id = "name"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
                  <Form.Input
                  placeholder="Surname"
                  id = "surname"
                  value={surname}
                  onChange={({ target }) => setSurname(target.value)}
                />
                <Form.Input
                  placeholder="Identity Number"
                  id = "national_id"
                  value={national_id}
                  onChange={({ target }) => setNational_id(target.value)}
                />
                <Form.Input
                  placeholder="South African mobile number in the form 0829876543"
                  id = "mobile_number"
                  value={mobile_number}
                  onChange={handleMobileChange}
                /> 

                {!isValidNumber && (
                  <div>Valid South African mobile number required e.g. 0821231234</div>
                )} 

                <Form.Input
                  placeholder="Email address"
                  id = "email_address"
                  value={email_address}
                  onChange={handleEmailChange}
                />

                {!isValidEmail && (
                  <div>Valid email address required</div>
                )} 
                <Form.Input
                  placeholder="ICD-10 Code"
                  id = "icd_10_c0de"
                  value={icd_10_code}
                  onChange={({ target }) => setIcd_10_code(target.value)}
                />

                <Form.DividingLine></Form.DividingLine>
                <AccordionTests.LongContainer>
                <Form.Input
                  placeholder="Medical Aid Name"
                  id = "medaid_name"
                  value={medaid_name}
                  onChange={({ target }) => setMedaid_name(target.value)}
                />
                <Form.Input
                  placeholder="Medical Aid Number"
                  id = "medaid_number"
                  value={medaid_number}
                  onChange={({ target }) => setMedaid_number(target.value)}
                />
                </AccordionTests.LongContainer>
                <AccordionTests.LongContainer>
                <Form.Input
                  placeholder="Medical Aid Plan"
                  id = "medaid_plan"
                  value={medaid_plan}
                  onChange={({ target }) => setMedaid_plan(target.value)}
                />
                <Form.Input
                  placeholder="Medical Aid Depandant Code"
                  id = "medaid_dep_code"
                  value={medaid_dep_code}
                  onChange={({ target }) => setMedaid_dep_code(target.value)}
                />
                </AccordionTests.LongContainer>
                <Form.DividingLine></Form.DividingLine>


                { isValidEmail || isValidNumber ?
                  <AccordionTests.SelectText>Notify patient by:</AccordionTests.SelectText>
                  : null 
                }

                <AccordionTests.LongContainer>
                { isValidEmail?
                  <>
                    <AccordionTests.CheckBox id = "patient-email" name="email" checked = {patientEmail === "true"} onChange={handleCommsBoxChange}></AccordionTests.CheckBox>
                    <AccordionTests.SelectText>Email</AccordionTests.SelectText>
                  </> 
                  : null 
                }
                { isValidNumber?
                  <>
                    <AccordionTests.CheckBox id = "patient-whatsapp" name="whatsapp" checked = {patientWhatsapp === "true"} onChange={handleCommsBoxChange}></AccordionTests.CheckBox>
                    <AccordionTests.SelectText>WhatsApp</AccordionTests.SelectText>
                    <AccordionTests.CheckBox id = "patient-sms" name="sms" checked = {patientSMS === "true"}  onChange={handleCommsBoxChange}></AccordionTests.CheckBox>
                    <AccordionTests.SelectText>SMS</AccordionTests.SelectText>
                  </>
                  : 
                  null
                }
                
                </AccordionTests.LongContainer>
                  {/* {loading  ? <Loading> </Loading>: <></>} */}
                { ( /*profile.user_type === "practice" || */ profile.user_type === "lab")?   
                  <>
                    <Form.Button type="submit" > {/*onclick={handleUpdate}*/}
                      Search patients by ID / Email / Mobile
                    </Form.Button>

                    { Object.keys(patientRows).length === 0 ? <><p>No Results from Patient Search Yet</p></> :  
                      <>
                        <Form.Text >Search results: select a patient below</Form.Text>

                        {patientRows.map((patientItem) => (
                          ( selectedPatient.user_id === patientItem.user_id? 
                            <>
                              <Form.OptionButton style={{ background: 'turquoise' }} id={patientItem.user_id} onClick={selectPatientClick}>
                                <Form.Text >{patientItem.name} {patientItem.surname} </Form.Text>
                                <Form.Text >ID: {patientItem.national_id} </Form.Text>
                                <Form.Text >{patientItem.mobile_number}</Form.Text>
                                <Form.Text >{patientItem.email_address}</Form.Text>
                              </Form.OptionButton>
                            </>
                            :  
                            <>
                              <Form.OptionButton id={patientItem.user_id} onClick={selectPatientClick} >
                                <Form.Text >{patientItem.name} {patientItem.surname} </Form.Text>
                                <Form.Text >ID: {patientItem.national_id} </Form.Text>
                                <Form.Text >{patientItem.mobile_number}</Form.Text>
                                <Form.Text >{patientItem.email_address}</Form.Text>
                              </Form.OptionButton>
                            </>
                          )))
                        };
                        
                        <Form.DividingLine> </Form.DividingLine>
                      </>
                    }
                  </>
                  :
                  <></>
                }

                { (isValidEmail || isValidNumber) && (email_address !== '' || mobile_number !== '') ? 
                  null
                  :       
                  <OptForm.Text >Please enter a valid mobile number or email to proceed</OptForm.Text>
                } 
                <Form.DividingLine> </Form.DividingLine>

                { profile.user_type === "practice"? 
                  <>
                    <AccordionTests.Title>Prescribing Doctor:</AccordionTests.Title>
                    { Object.keys(practiceDoctors).length === 0 ? <><p>No Doctors Found</p></> 
                    :  
                      <>
                        <Form.Text >Select a doctor below</Form.Text>    
                        {practiceDoctors.map((doctor) => (
                          (selectedDoctor.user_id === doctor.user_id? 
                            <>
                              <Form.OptionText style={{ background: 'turquoise' }} id={doctor.user_id} onClick={selectDoctorClick}>
                                {doctor.name} {doctor.surname} 
                              </Form.OptionText>
                            </>
                          :  
                            <>
                              <Form.OptionText id={doctor.user_id} onClick={selectDoctorClick} >
                                {doctor.name} {doctor.surname}
                              </Form.OptionText>
                            </>
                          )
                        ))}
                        <Form.DividingLine> </Form.DividingLine>
                      </>
                    }
                  </>
                  :
                  null
                }

                 { (profile.user_type === "patient" || profile.user_type === "lab")?
                  /* not a doctor's view, show fields to fill in doctors information */
                  <>
                    <AccordionTests.Title>Requesting Doctor:</AccordionTests.Title>
                      <Form.Input
                        placeholder="Doctor's Name e.g. Christiaan"
                        id = "doctors_first_name"
                        value={doctors_first_name}
                        onChange={({ target }) => setDoctors_first_name(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's Surname e.g. Barnard"
                        id = "doctors_surname"
                        value={doctors_surname}
                        onChange={({ target }) => setDoctors_surname(target.value)}
                      />  
                      <Form.Input
                        placeholder="Doctor's Location e.g. Groote Schuur Hospital"
                        id = "doctors_location"
                        value={doctors_location}
                        onChange={({ target }) => setDoctors_location(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's Practice Name e.g. Heartcare Clinic"
                        id = "doctors_practice_name"
                        value={doctors_practice_name}
                        onChange={({ target }) => setDoctors_practice_name(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's Suburb e.g. Observatory"
                        id = "doctors_suburb"
                        value={doctors_suburb}
                        onChange={({ target }) => setDoctors_suburb(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's City e.g. Cape Town"
                        id = "doctors_city"
                        value={doctors_city}
                        onChange={({ target }) => setDoctors_city(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's Province e.g. Western Cape"
                        id = "doctors_province"
                        value={doctors_province}
                        onChange={({ target }) => setDoctors_province(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's Telephone Number e.g. 012 123 4567"
                        id = "doctors_telephone"
                        value={doctors_telephone}
                        onChange={({ target }) => setDoctors_telephone(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's Email Address"
                        id = "doctors_email_address"
                        value={doctors_email_address}
                        onChange={({ target }) => setDoctors_email_address(target.value)}
                      />
                      <Form.Input
                        placeholder="Doctor's Practice Number"
                        id = "doctors_practice_number"
                        value={doctors_practice_number}
                        onChange={({ target }) => setDoctors_practice_number(target.value)}
                      />
                  </>
                  :
                  <></>
                }
                { (profile.user_type === "practice" || profile.user_type === "lab")?
                  <>
                    <Form.Text>Notify doctor by:</Form.Text>
                    <Form.LongLeftContainer>
                      <Form.CheckBox id ="doctor-whatsapp" onChange={handleCommsBoxChange} checked={doctorWhatsapp === "true"}/>
                      <Form.Text>WhatsApp</Form.Text>
                      <Form.CheckBox id ="doctor-email" onChange={handleCommsBoxChange} checked={doctorEmail === "true"}/>
                      <Form.Text>Email</Form.Text>
                      <Form.CheckBox id ="doctor-sms" onChange={handleCommsBoxChange} checked={doctorSMS === "true"}/>                      
                      <Form.Text>SMS</Form.Text>
                    </Form.LongLeftContainer>

                    <Form.Text>Notify doctor of: </Form.Text>
                    <Form.LongLeftContainer>
                      <Form.CheckBox id = "doctor-notify-prelim" onChange={handleCommsBoxChange} checked={ reportNotifyPrelim === "true"}></Form.CheckBox>
                      <Form.Text>Preliminary Results</Form.Text>
                      <Form.CheckBox id = "doctor-notify-abnormal" onChange={handleCommsBoxChange} checked={reportNotifyAbnormal === "true"}></Form.CheckBox>
                      <Form.Text>Abnormal Results</Form.Text>
                    </Form.LongLeftContainer>
                    <Form.Text>Release Results to Patient: </Form.Text>
                    <Form.LongLeftContainer>
                      <Form.CheckBox id = "release-results-normal" onChange={handleCommsBoxChange} checked={reportReleaseNormal === "true"}></Form.CheckBox>
                      <Form.Text>If all in-range after </Form.Text>
                      <Form.InputNarrow placeholder="0" id = "release-period" value = {reportReleasePeriod} onChange={(e) => setReportReleasePeriod(e.target.value)}></Form.InputNarrow> 
                      <Form.Text> hours</Form.Text>
                    </Form.LongLeftContainer>
                  </>
                  : <></>
                }

                <Form.DividingLine> </Form.DividingLine>
            
                { (isValidEmail || isValidNumber) && (email_address !== '' || mobile_number !== '') ?        
                  <>
                    {/* <OptForm.Button onClick={() => selectLocationClick()}>Select Location</OptForm.Button> */}
                    <OptForm.Button onClick={() => orderTestsClick()}>Proceed to Order</OptForm.Button>
                    <OptForm.Break />
                    <OptForm.Text>Proceed to select the sample collection location</OptForm.Text>
                  </> 
                  :
                  null 
                }
              </Form.Base>     
            </AccordionTests>
          </>
          :
          <></>
        }

    
    {locationMode?
      <>
        <AccordionTests>
        <OptForm.BackButton onClick={() => handleBackClick()}>&lt; Back to Select Tests</OptForm.BackButton>
          <AccordionTests.Title>Where should we take your sample?</AccordionTests.Title>
          
          { (props.profile.user_type !== "patient" && Object.keys(props.profile).length !== 0)? 
          <>
          <AccordionTests.LongContainer>
              <AccordionTests.SelectBox id = "0" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText>In Doctor's Rooms</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer>  
          </>:null}
            <AccordionTests.LongContainer>
              <AccordionTests.SelectBox id = "3" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText>Pretoria East Hospital - Oncolab</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer>  
 {/*            <AccordionTests.LongContainer>
              <AccordionTests.SelectBox id = "5" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText>Brooklyn, Pretoria - Integrative Family Medicine</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer> */}
            <AccordionTests.LongContainer>
              <AccordionTests.SelectBox id = "7" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText>Oncolab Stat Lab at Wilgers Hospital</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer>
            <AccordionTests.LongContainer>
              <AccordionTests.SelectBox disabled id = "4" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText style={{color: "#36454F"}}>Nurse visit within 5 km of the above locations - coming soon</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer>
            <AccordionTests.LongContainer>
              <AccordionTests.SelectBox disabled id = "1" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText style={{color: "#36454F"}}>Benoni - Oncolab - coming soon</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer>  
            <AccordionTests.LongContainer>
              <AccordionTests.SelectBox disabled id = "2" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText style={{color: "#36454F"}}>Midstream Medical Centre - Oncolab - coming soon</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer>
            <AccordionTests.LongContainer>
              <AccordionTests.SelectBox disabled id = "6" name='selectSite' onChange={handleRadioInputChange}></AccordionTests.SelectBox>
                <AccordionTests.SelectText style={{color: "#36454F"}}>Garsfontein, Pretoria - Oncolab - coming soon</AccordionTests.SelectText> 
                <AccordionTests.RightText></AccordionTests.RightText>
            </AccordionTests.LongContainer>

            <OptForm.Break />
            {/* <OptForm.Button onClick={() => selectTestsClick()}>Select Tests</OptForm.Button> */}
            <OptForm.Button onClick={() => enterDetailsClick()}>Patient Details</OptForm.Button>
            <OptForm.Break />
            <OptForm.Text>Proceed to enter patient details</OptForm.Text>
            {/* <OptForm.Text>Proceed to test selection with entered details</OptForm.Text> */}
            <Form.DividingLine> </Form.DividingLine>
        </AccordionTests>
      </>:
      <></>
    }

    {selectMode ?
      <>
      <AccordionTests>
      <OptForm.BackButton onClick={() => handleBackClick()} >&lt; Back </OptForm.BackButton>
      <OptForm>
        {/* <OptForm.Button onClick={() => orderTestsClick()}>Proceed to Order</OptForm.Button> */}
        <OptForm.Button onClick={() => selectLocationClick()}>Select Location</OptForm.Button>
        <OptForm.Text>Proceed to select the sample collection location</OptForm.Text>
        {/* <OptForm.Text>Reserve your test slot now.</OptForm.Text> */}
        <OptForm.Break />
      </OptForm>
      <AccordionTests.Title>Please select your tests:</AccordionTests.Title>
      {(props.profile.user_type === "patient" || Object.keys(props.profile).length === 0)?
      <OptForm.LeftText>Please note that due to current HPCSA regulations, tests may only be ordered if a doctor has provided you with a test request form.</OptForm.LeftText>
      :null}
      <AccordionTests.SelectText htmlFor="phlebotomy_notes">Sample Notes:</AccordionTests.SelectText>
            <textarea id="phlebotomy_notes" name="comments" rows="6" cols="50"
                type="text"
                value={phlebotomy_notes}
                onChange={({ target }) => setPhlebotomy_notes(target.value)}>
            </textarea>
      {/* NewSearch !!!!  */}
      <Form.DividingLine></Form.DividingLine>
      <Form.Input
            placeholder="Search tests by name or code"
            value={searchQuery}
            onChange={handleSearchChange}
          />
       {/* Display search results */}        
       <AccordionTests.SearchHeading>Tests from EZPath labs</AccordionTests.SearchHeading>
       {Object.keys(searchResults).length > 0 ? (
        <>

        {Object.entries(searchResults).map(([category,items]) => (
        <div key={category}>
          <AccordionTests.SearchCategory>{category}</AccordionTests.SearchCategory>

          <AccordionTests.Item>
          {items.map((test, index) => (
            <>
            <AccordionTests.LongSearchContainer>
            {test.tariff_code ? (
                      <AccordionTests.TariffText>
                        {'('}
                        {test.tariff_code}
                        {')'}
                      </AccordionTests.TariffText>
                    ) : (
                      <AccordionTests.TariffText> &nbsp; </AccordionTests.TariffText>
                    )}
                      <AccordionTests.CheckBox
                      id={test.id}
                      name="testBox"
                      checked={test.selected}
                      onChange={handleCheckboxChange}
                    />
                    <AccordionTests.LeftText>{test.long_name}</AccordionTests.LeftText>
                    <AccordionTests.RightText>R {formatNumberWithSpaceSeparator(test.reduced_price, 0)}</AccordionTests.RightText>
                    {test.lab === '001' ? (
                      <AccordionTests.RightText>&#8595;</AccordionTests.RightText>
                    ) : null}
            </AccordionTests.LongSearchContainer>
            </>
          )
          )}

          </AccordionTests.Item>
          </div>
      ))}
       
              {/* {searchResults.map(test => (
                  
                  <AccordionTests.LongContainer>
                    {test.tariff_code ? (
                      <AccordionTests.TariffText>
                        {'('}
                        {test.tariff_code}
                        {')'}
                      </AccordionTests.TariffText>
                    ) : (
                      <AccordionTests.TariffText> &nbsp; </AccordionTests.TariffText>
                    )}
                    <AccordionTests.CheckBox
                      id={test.id}
                      name="testBox"
                      checked={test.selected}
                      onChange={handleCheckboxChange}
                    />
                    <AccordionTests.LeftText>{test.long_name}</AccordionTests.LeftText>
                    <AccordionTests.RightText>R {formatNumberWithSpaceSeparator(test.reduced_price, 0)}</AccordionTests.RightText>
                    {test.lab === '001' ? (
                      <AccordionTests.RightText>&#8595;</AccordionTests.RightText>
                    ) : null}
                  </AccordionTests.LongContainer> 
                
                  )
                )
              }
                */}
            
            </>
          ) : 
          <OptForm.Text>No search results...</OptForm.Text>
          } 
            <Form.DividingLine></Form.DividingLine>
         {/* end NewSearch !!!!  */}

         <AccordionTests.SearchHeading>Tests from non-EZPath labs</AccordionTests.SearchHeading>
      {(Object.keys(searchExternalResults).length === 0) ?  
        <>
            <OptForm.Text>No search results...</OptForm.Text>
        </>:null}
        
      {(Object.keys(searchExternalResults).length > 0) ?
        <>
        <AccordionTests.Item>
          {searchExternalResults.map((test, index) => (
            <>
            <AccordionTests.LongSearchContainer>
            {test.item.tariff_code ? (
                      <AccordionTests.TariffText>
                        {'('}
                        {test.item.tariff_code}
                        {')'}
                      </AccordionTests.TariffText>
                    ) : (
                      <AccordionTests.TariffText> &nbsp; </AccordionTests.TariffText>
                    )}
                    <AccordionTests.CheckBox
                      id={test.item.id}
                      name="testBox"
                      checked={test.item.selected}
                      onChange={handleExternalCheckboxChange}
                    />
                    <AccordionTests.LeftText>{test.item.long_name}</AccordionTests.LeftText>
                    <AccordionTests.RightText>R {formatNumberWithSpaceSeparator(test.item.reduced_price, 0)}</AccordionTests.RightText>
            </AccordionTests.LongSearchContainer>
            </>
          )
          )}
          </AccordionTests.Item>
        </>:null}
        <Form.DividingLine></Form.DividingLine>

      <AccordionTests.Frame>
      {/* {(Object.keys(testGroups).length === 0)? */}
      {(Object.keys(filteredTests).length === 0)?  
      <>
        <OptForm.Text>Unable to retrieve tests</OptForm.Text>
        <OptForm.Text>Please check your connection</OptForm.Text>
      </>
      :
      <>
      {filteredTests.map((item) => (
          <AccordionTests.Item toggle={false}>
            <AccordionTests.Header
              name = {item.category}
              style={{ backgroundColor: item.selected ? 'rgba(200,100,20,0.50)' : ''}}>
              {item.category}
            </AccordionTests.Header>
            {item.data.map((testItem, index) => (
              <AccordionTests.Body key = {"ft-"+index}>
                <AccordionTests.LongContainer>
                  {testItem.tariff_code?
                  <AccordionTests.TariffText>{"("}{testItem.tariff_code}{")"}</AccordionTests.TariffText>:<AccordionTests.TariffText>   </AccordionTests.TariffText>}
                  <AccordionTests.CheckBox
                    key={index}
                    id = {testItem.id} name='testBox' 
                    checked = {testItem.selected}
                    onChange={handleCheckboxChange}>
                  </AccordionTests.CheckBox>
                    <AccordionTests.LeftText>{testItem.long_name}</AccordionTests.LeftText> 
                    <AccordionTests.RightText>R {formatNumberWithSpaceSeparator(testItem.reduced_price,0)}</AccordionTests.RightText>
                    {testItem.lab === "001"? 
                      <AccordionTests.RightText>&#8595;</AccordionTests.RightText>
                      :
                      <></>
                    }
                </AccordionTests.LongContainer>
              </AccordionTests.Body>
            ))}
          </AccordionTests.Item>
          
          ))}


{externalTests ? ( // Check if externalTests is not null
  <>
  <OptForm.Text>External tests</OptForm.Text>
    {externalTests.length > 0 ? (
      <>
         {externalTests.some(test => test?.selected) ? (
          <>
          <AccordionTests.Item toggle={false}>
            <AccordionTests.Header style={{ backgroundColor: 'rgba(200,100,20,0.50)' }}>
              Non-EZPath Lab Tests</AccordionTests.Header>
      
            {externalTests.filter(item => item.selected).map((item, index) => (
                <AccordionTests.Body>
                  <AccordionTests.LongContainer>
                    {item.tariff_code ? (
                      <AccordionTests.TariffText>{"("}{item.tariff_code}{")"}</AccordionTests.TariffText>
                    ) : (
                      <AccordionTests.TariffText> </AccordionTests.TariffText>
                    )}
                    <AccordionTests.CheckBox
                      key={index}
                      id={item.id}
                      name='testBox'
                      checked={item.selected}
                      onChange={handleExternalCheckboxChange}
                    />
                    <AccordionTests.LeftText>{item.long_name}</AccordionTests.LeftText>
                    <AccordionTests.RightText>R {formatNumberWithSpaceSeparator(item.reduced_price, 0)}</AccordionTests.RightText>
                  </AccordionTests.LongContainer>
                </AccordionTests.Body>
            ))}

            </AccordionTests.Item>
          </>
        ) : null}
          </>
        ) : null} 
        </>
        ) : (
          <div>Loading...</div> // Show a loading indicator while fetching
        )}




        { ( /*profile.user_type === "practice" || */ profile.user_type === "lab")?   
          <> 
          <AccordionTests.Item toggle={false}>
          <AccordionTests.Title>Additional tests input:</AccordionTests.Title>
          <AddOnTest onTestsChange={handleAddOnTestsChange} ref={addOnTestRef} ></AddOnTest>
          </AccordionTests.Item>
          </>
          :
          <></>}
        </>}


        <AccordionTests.SubTotal id = {'sub_total'}>SubTotal    R {formattedSelectSubTotal}</AccordionTests.SubTotal>
          <AccordionTests.SubTotal id = {'VAT'}>VAT    R {formattedSelectVatAmount}</AccordionTests.SubTotal>
          <AccordionTests.TotalPrice id = {'pf_amount'}>Total    R {formattedSelectTotal}</AccordionTests.TotalPrice>
      </AccordionTests.Frame>

      <OptForm>
        {/* <OptForm.Button onClick={() => orderTestsClick()}>Proceed to Order</OptForm.Button> */}
        <OptForm.Button onClick={() => selectLocationClick()}>Select Location</OptForm.Button>
        <OptForm.Break></OptForm.Break>
        <OptForm.Text>Proceed to select the sample collection location</OptForm.Text>
      </OptForm>
    </AccordionTests>
    </>
    :
    <></>
  }

  {payMode ?
        <>
        <AccordionTests>
        <OptForm.BackButton onClick={() => testsBackClick()} >&lt; Back to Patient Details</OptForm.BackButton>
        <AccordionTests.Title>Medical Test Order</AccordionTests.Title>
        { Object.keys(selectedPatient).length === 0 ? <>
          <Form.TextSmall>Name: {name} {surname}</Form.TextSmall>
          <Form.TextSmall>ID: {national_id}</Form.TextSmall>
          <Form.TextSmall>Mobile: {mobile_number}</Form.TextSmall>
          <Form.TextSmall>Email: {email_address}</Form.TextSmall>
          <Form.TextSmall>Collection Site: {selectedSite.site_name}</Form.TextSmall>
          <Form.TextSmall>ICD-10 Code: {icd_10_code}</Form.TextSmall>
        </> :  
        <>
          <Form.TextSmall>Name: {selectedPatient.name} {selectedPatient.surname}</Form.TextSmall>
          <Form.TextSmall>ID: {selectedPatient.national_id}</Form.TextSmall>
          <Form.TextSmall>Mobile: {selectedPatient.mobile_number}</Form.TextSmall>
          <Form.TextSmall>Email: {selectedPatient.email_address}</Form.TextSmall>
          <Form.TextSmall>Collection Site: {selectedSite.site_name}</Form.TextSmall>
          <Form.TextSmall>ICD-10 Code: {icd_10_code}</Form.TextSmall>
          </>
          }
          <Form.LongLeftContainer>
              <Form.TextSmall>{"Patient Notifications: "+ " "}</Form.TextSmall>
              {(patientSMS === "true")?<Form.TextSmall>{". SMS ."}</Form.TextSmall>:null}
              {(patientEmail === "true")?<Form.TextSmall>{". Email ."}</Form.TextSmall>:null}
              {(patientWhatsapp === "true")?<Form.TextSmall>{". WhatsApp ."} </Form.TextSmall>:null}
              {(!(patientSMS==="true") && !(patientWhatsapp==="true") && !(patientEmail==="true"))?<Form.TextSmall> None Selected!</Form.TextSmall>:null}
          </Form.LongLeftContainer>
          <Form.DividingLine> </Form.DividingLine>
        <AccordionTests.Frame>
        
        {checkoutTests?.map((group) => (
        <div key={group.category}>
          <AccordionTests.PriceCategory>{group.category}</AccordionTests.PriceCategory> {/* Display the category as a header */}
          {group.data.map((item) => (
            <AccordionTests.Item key={item.id} toggle={true}>
              <AccordionTests.Body align={'right'}>
                <AccordionTests.LongContainer>
                  <AccordionTests.LeftText>{item.long_name}</AccordionTests.LeftText>
                  <AccordionTests.RightText>R {formatNumberWithSpaceSeparator(item.reduced_price,0)}</AccordionTests.RightText>
                  {item.lab === "001"? 
                      <AccordionTests.RightText>&#8595;</AccordionTests.RightText>
                      :
                      <></>
                    }
                </AccordionTests.LongContainer>
              </AccordionTests.Body>
            </AccordionTests.Item>
          ))}
        </div>
      ))}


          {selectedSite.site_id === "4"? <>
            <AccordionTests.Item toggle={true}>
                <AccordionTests.Body align = {'right'}>
                <AccordionTests.LongContainer>
                  <AccordionTests.LeftText>Phlebotomy</AccordionTests.LeftText> 
                  <AccordionTests.LeftText>Home Phlebotomy - 5km Radius</AccordionTests.LeftText> 
                    <AccordionTests.RightText>R{phlebotomyHomeFee}</AccordionTests.RightText>
                  </AccordionTests.LongContainer>
                </AccordionTests.Body>
            
            </AccordionTests.Item>
          </>:
          <></>}
          
          <AccordionTests.SubTotal id = {'sub_total'}>SubTotal    R {formattedSubTotal}</AccordionTests.SubTotal>
          <AccordionTests.SubTotal id = {'VAT'}>VAT    R {formattedVat}</AccordionTests.SubTotal>
          <AccordionTests.TotalPrice id = {'pf_amount'}>Total    R {formattedTotal}</AccordionTests.TotalPrice>
        </AccordionTests.Frame>

        <Form.DividingLine> </Form.DividingLine>
        <AccordionTests.InfoButton id="DownloadButton" onClick={handleSummaryDownload} >View Order Summary</AccordionTests.InfoButton>
        { profile.user_type === "lab" ?
          <>
            <AccordionTests.InfoButton id="DownloadCSVButton" onClick={(e) => downloadCSV(e)}>Output to CSV</AccordionTests.InfoButton>
          </>
          :
          null
        }
       {/*  {selectedSite.site_id === "3"?
        <AccordionTests.InfoButton id="DirectionsButton" onClick={handleDirectionsDownload} >View Directions to Site</AccordionTests.InfoButton>
        :<></>} */}
        <Form.DividingLine> </Form.DividingLine>

{/*******************PAYFAST INTEGRATION SECTION**********************
**********************************************************************/}
        <OptForm id="PayNowForm" onSubmit={payClick} method="POST">
          <form id="payForm" action={pf_url} method="POST">
          <input id="pf_element" type="hidden" name="merchant_id" value={pf_merchant_id} />
          <input id="pf_element" type="hidden" name="merchant_key" value={pf_merchant_key} />
          <input id="pf_element" type="hidden" name="return_url" value={pf_return_url}/>
          <input id="pf_element" type="hidden" name="cancel_url" value={pf_cancel_url}/>
          <input id="pf_element" type="hidden" name="notify_url" value={pf_notify_url}/>
          {/*
          <input type="hidden" name="name_first" value={name} />
          <input type="hidden" name="email_address" value={email} />
          */}
          <input id="pf_element" type="hidden" name="m_payment_id" value={pf_m_payment_id} /> 
          <input id="pf_element" type="hidden" name="amount" value={pf_amount} />
          <input id="pf_element" type="hidden" name="item_name" value={pf_item_name} />
          {/* <input type="hidden" name="signature" value={pf_signature} /> */}
          {/* <input type="hidden" name="item_description" value={description_if_any} />
          <input type="hidden" name="custom_int1" value={custome_integer_value_if_any} />
          <input type="hidden" name="custom_str1" value={custome_string_value_if_any} />
          <input type="hidden" name="custom_str2" value={custome_string_value_if_any} />
          <input type="hidden" name="passphrase" value={pf_passPhrase} />*/}
          <input type="hidden" name="signature" value={pf_signature} /> 
          <OptForm.Button type="submit">Pay Now with PayFast</OptForm.Button>
          <OptForm.Text>Make your payment now</OptForm.Text>
        </form>
        </OptForm>
        <Form.DividingLine> </Form.DividingLine>

{/*******************END OF PAYFAST INTEGRATION SECTION**********************
*****************************************************************************/}
          
            <OptForm.BackButton id="PayLaterButton" onClick={payClick} >Pay later when the sample gets taken</OptForm.BackButton>
          
      </AccordionTests>
      </>
      :
      <></>
    }
  </>
 )  
}