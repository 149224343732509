import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  doc,
} from 'firebase/firestore';

export default async function moveOrderToDiscarded(payment_id, name, surname, role) {
  try {
    const discarded_date = new Date();
    const db = getFirestore();

    // Get documents from 'order_items' collection 
    const orderItemsRef = collection(db, 'order_items');
    const paymentQuery = query(orderItemsRef, where('m_payment_id', '==', payment_id)); 
    const querySnapshot = await getDocs(paymentQuery);

    // Create a Firestore batch (using writeBatch in Firebase 9)
    const batch = writeBatch(db);

    querySnapshot.forEach(document => {
        const data = document.data();
        const discarded_role = role;
        const discarded_type = "order"; 
        const discarded_by = (name || 'unknown') + ' ' + (surname || 'unknown'); 
        const discardedData = { ...data, discarded_by, discarded_date, discarded_type, discarded_role };

        // Use the doc function to create the reference for the new document
        const discardRef = doc(db, 'discarded_items', document.id); 

        // Add operations to the batch
        batch.set(discardRef, discardedData);
        batch.delete(document.ref);
    });

    // Commit the batch operation
    await batch.commit(); 

    console.log('Order ' + payment_id +': Documents moved to discarded_items and deleted successfully.'); 
  } catch (error) { 
    console.error('Error moving and deleting order documents:', error);
  }
}

  /* try {
    const discarded_date = new Date();

    // Get documents from order_pay_later_items collection based on m_payment_id
    const querySnapshot = await firebase.firestore().collection('order_items')
      .where('m_payment_id', '==', payment_id)
      .get();

    // Iterate through the documents and move them to discarded_plo_items
    const batch = firebase.firestore().batch();
    querySnapshot.forEach(doc => {
      const data = doc.data();
      const discarded_role = role;
      const discarded_type = "order"; // whole order as opposed to single test
      const discarded_by = (name || 'unknown') + ' ' + (surname || 'unknown'); // Handling blank or null values
      const discardedData = { ...data, discarded_by, discarded_date, discarded_type, discarded_role };
      batch.set(firebase.firestore().collection('discarded_items').doc(doc.id), discardedData);
      batch.delete(doc.ref); // Delete the document from order_pay_later_items
    });

    // Commit the batch operation
    await batch.commit();

    console.log('Pay Later Order ' + payment_id +': Documents moved to discarded_plo_items and deleted successfully.');
  } catch (error) {
    console.error('Error:', error);
  } */

