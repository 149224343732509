import React from 'react';
import { LockBody, ReleaseBody, Spinner, Picture, LocalSpinner, Overlay, SpinnerBox, Message } from './styles/loading';

export default function Loading({ src, ...restProps }) {
  return (
    <Spinner {...restProps}>
      <LockBody />
      <Picture src={src} data-testid="loading-picture" />
    </Spinner>
  );
}

Loading.ReleaseBody = function LoadingReleaseBody() {
  return <ReleaseBody />;
};


Loading.SmallSpinner = function LoadingSmallSpinner({apiMessage, ...restProps}) {
  return (
    <Overlay>
      <SpinnerBox>
        <LocalSpinner />
        {apiMessage && <Message>{apiMessage}</Message>} 
      </SpinnerBox>
    </Overlay>
  )
}
