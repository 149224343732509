import styled, { createGlobalStyle } from 'styled-components';

export const LockBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const ReleaseBody = createGlobalStyle`
  body {
    overflow: visible;
  }
`;

export const Spinner = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-image: url(/images/misc/spinner.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -175px;
    margin-left: -100px;
    width: 300px;
    height: 300px;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Picture = styled.img`
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -22px;
`;

export const LocalSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px; 

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg);}
  }
`;

export const SpinnerBox = styled.div`
  display: flex;
  width: 500px;
  height: 150px; // Add the missing colon 
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;  // Add this line to center vertically
  background: rgba(50, 50, 50, 0.5);
`;

export const Message = styled.p`
 font-size: 18px;
  color: rgba(255,255,255,100);
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0;
`

// Overlay component
export const Overlay = styled.div`
  position: fixed; /* Stay in place even when scrolling */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(70, 70, 70, 0.5); /* Semi-transparent background   
 */
  z-index: 1000; /* Ensure it's on top of other elements */
`;
